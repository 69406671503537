%clearfix {
    &::before,
    &::after {
        content: " ";
        display: table;
    }

    &::after {
        clear: both;
    }
}

@mixin clearfix($extend: false) {
    @if $extend {
        @extend %clearfix;
    } @else {
        &::before,
        &::after {
            content: " ";
            display: table;
        }

        &::after {
            clear: both;
        }
    }
}
