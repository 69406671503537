.quicklinks {
  &--red {
    background: $color-red-berry;
    background: linear-gradient(90deg, $color-red-berry 0%, $color-red 55%);
    color: $color-white;

    button {
      color: $color-white;
    }

    .icon {
      color: $color-white;
    }
  }

  &__navigation {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    text-align: center;
    width: 100%;
    padding: 10px 10px 0;
  }

  button {
    &.quicklinks__item {
      background-color: transparent;
      border: 0;
      cursor: pointer;
    }
  }

  &__item {
    display: table-cell;
    vertical-align: top;
    text-align: center;
    padding: 28px 0;
    position: relative;

    &:hover {
      .quicklinks__icon {
        transform: scale(1.2);
      }
    }
  }

  &__icon {
    display: block;
    margin: auto;
    padding: 13px;
    width: 64px;
    height: 64px;
    transform: scale(1);
    transition: transform 0.4s;
    border-radius: 50%;
    border: 1px solid $color-white;

    svg {
      width: 36px;
      height: 36px;
    }
  }

  &__heading {
    font-size: 18px;
    margin-top: 16px;
    display: block;
    font-family: "VodafoneLight";
  }
}

@media only screen and (max-width: 1024px) {
  .quicklinks {
    &__item {
      &:hover {
        .quicklinks__icon {
          transform: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 968px) {
  .quicklinks {
    &__navigation {
      flex-wrap: wrap;
    }

    &__item {
      width: 100%;
      max-width: calc((100% / 3) - 20px);
      margin: 0 10px;
      padding: 20px 0;
    }
  }
}

@media only screen and (max-width: 639px) {
  .quicklinks {
    &__icon {
      width: 48px;
      height: 48px;
      padding: 10px;

      svg {
        height: 24px;
        width: 24px;
      }
    }

    &__heading {
      font-size: 14px;
    }
  }
}
