.header {
    position: relative;
    width: 100%;
    z-index: 10;

    &__sticky {
        position: fixed;
    }
}

@media only screen and (max-width: $breakpoint-desktop) {
    .header {
        &__global {
            float: left;
        }
    }
}
