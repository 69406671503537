.tiles {
  &__box {
    width: 100%;
    min-height: 572px;
    border-radius: 6px;
    background-color: #ffffff;
    margin: 0 20px 0 0;
    padding: 16px 16px 0;
  }

  &__background {
    &--red {
      background-image: linear-gradient(66deg, #a90000, #e60000 55%, #ff0000);
      color: white;

      .tiles {
        &__chevron--text,
        &__title {
          color: white;
        }
      }
    }
  }

  &__image {
    height: 350px;
    position: relative;

    .background__image {
      border-radius: 6px;
    }
  }

  &__title {
    height: auto;
    text-align: center;

    &.heading {
      margin: 40px 0 20px;
      font-family: "VodafoneRegularBold";
      line-height: 1.21;
    }
  }

  &__link {
    display: block;
    width: 100%;
    height: auto;
    text-align: center;
    padding-bottom: 40px;
  }

  &__chevron--text {
    color: #e60000;
    font-size: 18px;
    font-family: "VodafoneRegular";
    cursor: pointer;

    .icon--extra-small {
      width: 24px;
      height: 24px;
      margin-left: 4px;
    }
  }

  &__horizontal {
    width: calc(100% - 20px);
    height: 276px;
    margin: 0px 0px 20px 20px;
    padding: 20px 32px;
    border-radius: 6px;
    background-color: #ffffff;

    .tiles {
      &__title {
        width: 50%;
        height: auto;
        font-family: "VodafoneRegularBold";
        text-align: left;
        display: inline-block;
        margin: 45px 0px 20px 0px;
      }

      &__image {
        width: 50%;
        height: 236px;
        margin: 0;
        float: right;
      }

      &__link {
        text-align: left;
        margin: 0 4px 0 0;
        padding: 20px 0;
      }
    }

    .background__image {
      background-size: contain !important;
      background-position: center;
    }
  }

  .heading--6 {
    font-size: 18px;
    line-height: 24px;
  }

  .background__image {
    background-size: 100%;
  }
}

.section {
  &--wild-sand {
    background-color: #f4f4f4;
  }

  &__title {
    padding: 64px 0 40px 0;
    margin: 0;

    &.heading {
      font-family: "VodafoneRegularBold";
    }
  }
}

// Desktop
@media only screen and (max-width: 1055px) {
  .tiles {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;

    &__item {
      width: auto;
      white-space: break-spaces;

      &--1\/2 {
        max-width: 80%;
      }
    }
  }
}

// Mobile
@media only screen and (max-width: 639px) {
  .tiles {
    overflow-x: unset;
    overflow-y: unset;
    white-space: unset;

    &__item {
      white-space: unset;

      &--1\/2 {
        max-width: 100%;
      }
    }

    &__box {
      min-height: auto;
    }

    &__horizontal {
      margin: 20px 0 0 0;
      width: 100%;
      height: auto;

      .tiles {
        &__image {
          height: 132px;
        }

        &__title {
          margin: 0;
        }
      }
    }

    &__image {
      height: 171px;
    }

    &__title {
      margin: 20px 4px 16px;
    }

    &__chevron--text {
      font-size: 16px;
    }

    &__link {
      padding-bottom: 20px;
    }

    .heading {
      &--4 {
        font-size: 20px;
      }

      &--6 {
        font-size: 16px;
      }
    }
  }
}
