.results {
    &__wrapper {
        background-color: $color-white;
    }

    &__item {
        background-color: $color-white;
        color: $color-mine-shaft;
        margin: -1px; // This is for the Chrome zoom border bug:
        padding: 1px; // https://stackoverflow.com/questions/12547580/borders-disappear-in-chrome-when-i-zoom-in

        &--offer {
            border: 1px solid $color-red;
            position: relative;

            &--save {
                border-color: $color-seance;
            }

            &--data {
                border-color: $color-blue-lagoon;
            }

            &--popular {
                border: none;
            }
        }

        &--alert {
            box-shadow: inset 0 0 0 4px $color-wild-sand;
            border-radius: 3px;
            border: 1px solid $color-red;
            overflow: hidden;
            position: relative;
        }

        &--extrude {
            background-color: $color-white;
            box-shadow: 1px 1px 3px 0 $color-silver-chalice;
            margin: 2px 0 3px;
        }

        &--last {
            margin-bottom: 0;
        }

        &--md-last {
            margin-bottom: 0;
        }
    }

    &__offer {
        background: $color-red;
        color: $color-white;
        font-size: 14px;
        font-family: $font-family-vodafone-regular-bold;
        line-height: 1;
        padding: 5px 10px;
        position: absolute;
        right: 0;
        text-transform: uppercase;
        top: 0;

        &--left {
            z-index: 1;
            left: 0;
            width: 40%;
        }
    }

    &__item--offer--save &__offer {
        background: $color-seance;
    }

    &__item--offer--data &__offer {
        background: $color-blue-lagoon;
    }

    &__alert {
        background-color: $color-red;
        color: $color-white;
        display: inline-block;
        font-family: $font-family-vodafone-light;
        padding: 80px;
        padding-bottom: 5px;
        position: absolute;
        right: -100px;
        text-transform: uppercase;
        transform: rotate(45deg);
        top: -50px;
    }

    &__help {
        padding: 20px;
    }

    &__description {
        margin-left: 20px;
    }
}

@media only screen and (max-width: $breakpoint-desktop) {
    .results {
        &__item {
            &--last {
                margin-bottom: 20px;
            }

            &--md-last {
                margin-bottom: 0;
            }
        }
    }
}

@media only screen and (max-width: $breakpoint-tablet) {
    .results {
        &__wrapper {
            padding: 10px;
        }

        &__item {
            &--last,
            &--md-last {
                margin-bottom: 20px;
            }

            &--sm-last {
                margin-bottom: 0;
            }
        }
    }
}
