.chevron {
  display: table;
  position: relative;
  width: 100%;

  &--inline {
    display: block;
  }

  &__text,
  &__container {
    display: table-cell;
    vertical-align: middle;
  }

  &__text {
    cursor: pointer;
    font-family: $font-family-vodafone-regular;
    font-size: 20px;
    display: table-cell;
    vertical-align: middle;
    width: 100%;

    &--wide {
      width: 100%;
      padding-right: 25px;
    }
  }

  &--inline &__text {
    display: inline;
  }

  &__container {
    line-height: 0;
    text-align: right;
    width: 20px;

    &--float {
      float: right;
    }
  }

  &--inline &__container {
    display: inline-block;
  }

  &__icon {
    font-size: 18px;

    &--inherit {
      color: inherit;
    }

    &--white {
      color: $color-white;
    }

    &--red {
      color: $color-red;
    }

    &--right {
      float: right;
    }
  }
}
