@media only screen and (min-width: $breakpoint-desktop + 1) {
  .hide--lg {
    display: none !important;
  }
}

@media only screen and (min-width: $breakpoint-tablet + 1) and (max-width: $breakpoint-desktop) {
  .hide--md {
    display: none !important;
  }
}

@media only screen and (max-width: $breakpoint-tablet) {
  .hide--sm {
    display: none !important;
  }
}
