.submenu {
  position: fixed;
  top: 98px; //top property should be inline style. Top = navigation height
  width: 100%;
  height: calc(100vh - 98px);
  z-index: 9;

  &[data-visible="off"] {
    visibility: hidden;
    width: 0;
    height: 0;
  }

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(black, 0.8);
    transition: opacity 0.4s ease-in-out;
    opacity: 0;
  }

  &__content {
    position: absolute;
    left: -45vw;
    width: 0;
    height: 0;
    background-color: white;
    transition: left 0.4s ease-in-out;
    overflow-x: hidden;
    border-bottom-right-radius: 12px;

    &--list,
    &--sublist {
      display: flex;
      flex-direction: column;
      list-style: none;
      margin: 0 0 0 auto;
      padding: 0;
      justify-content: center;
      height: 100%;

      .back-button {
        position: absolute;
        top: 25px;
        cursor: pointer;
      }
      .submenu__navigation {
        position: absolute;
        top: 0;
        display: flex;
        flex-direction: column;
        .submenu__page-name {
          font-size: 14px;
        }
      }
    }

    &--list {
      max-width: 450px;

      li {
        font-size: 40px;
        line-height: 48px;
        margin: 9px 0;
      }

      a {
        font-family: "VodafoneLight";
        cursor: pointer;
        color: #666666;
        border-bottom: 3px solid transparent;
        transition: color 0.25s;

        &:hover {
          color: #000000;
        }
      }

      .active a {
        color: #333333;
        border-bottom: 3px solid #e60000;
        font-family: "VodafoneRegularBold";
      }

      .back-button {
        left: 20px;
      }
    }

    &--sublist {
      padding: 0 20px 0 35px;
      position: relative;

      li {
        padding: 15px 0;
        border-bottom: 1px solid #ebebeb;
        cursor: pointer;
        font-size: 20px;
        display: flex;
        justify-content: space-between;

        svg {
          stroke: #e60000;
        }
      }

      .back-button {
        left: 35px;
      }
    }

    &--help {
      justify-content: flex-start;
      padding-top: 40px;

      .icon {
        background-color: white;
        border-radius: 50%;
        width: 64px;
        height: 64px;
        padding: 4px;
        box-shadow: 0 0 5px rgba(black, 0.16);
        margin-bottom: 10px;
      }

      h2,
      h4 {
        margin: 20px 0 15px;
      }

      button {
        width: fit-content;
        margin: 30px 0;
      }

      a:not(.back-button) {
        color: #e60000;
        font-family: "VodafoneRegular";
        font-size: 20px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
      }

      .badge {
        background-color: #e60000;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;

        span {
          color: white;
          font-size: 14px;
        }
      }

      ul {
        list-style: none;
        margin: 10px 0 0;
        padding: 0;
      }

      li {
        font-size: 40px;
        line-height: 48px;
        margin: 18px 0;
        font-family: "VodafoneLight";
        cursor: pointer;
      }
    }
  }

  &__mobile {
    height: 100%;

    > span,
    > .submenu__bottom--list {
      display: none;
    }
  }

  &__page-name {
    position: absolute;
    top: 65px;
    font-size: 14px;
    left: 22px;
  }

  a.submenu__link--red {
    color: #e60000;
    font-family: "VodafoneRegular";
    padding-bottom: 5px;
    cursor: pointer;

    &:hover {
      color: #aa9898;
    }

    
  }
  
  .__broadband{
    margin: 10px 0px 0px 0px;
  }

  &__level-2 {
    position: absolute;
    left: -25vw;
    width: 0;
    height: 0;
    background-color: #f4f4f4;
    transition: left 0.4s ease-in-out;
    overflow-x: hidden;
    border-bottom-right-radius: 12px;

    .spring {
      padding: 0 20px 0 40px;
    }
  }

  &__close {
    position: absolute;
    top: 25px;
    left: -50px;
    background: transparent;
    border: 0;
    cursor: pointer;
    transition: all 0.4s ease-in-out;

    &:hover,
    &:focus {
      border: 0;
      outline: 0;
    }

    &:hover {
      transform: rotate(-90deg);
    }

    svg {
      color: white;
      width: 22px;
      height: 22px;
    }
  }

  &__back-step {
    margin-bottom: 16px;

    button {
      background: transparent;
      border: 0;
      padding: 0;
      cursor: pointer;

      &:focus,
      &:hover {
        border: 0;
        outline: none;
      }
    }

    svg {
      width: 18px;
      height: auto;
    }

    span {
      padding-left: 8px;
    }
  }

  &[data-visible="on"] {
    visibility: visible;

    &:before {
      opacity: 1;
    }

    .submenu {
      &__content {
        width: 45vw;
        height: 100%;
        left: 0;
        z-index: 2;
        box-shadow: 0 2px 8px 0 rgba(black, 0.16);
      }

      &__close {
        left: calc(45vw + 30px);
      }
    }
  }

  &[data-level3="on"] {
    visibility: visible;

    .submenu {
      &__level-3 {
        width: calc(350px + 15px);
        height: 100%;
        left: calc(45vw - 15px);
        z-index: 1;
        box-shadow: 0 2px 8px 0 rgba(black, 0.16);
      }

      &__close {
        left: calc((45vw + 350px) + 30px);
      }
    }
  }

  &__list {
    padding-top: 80px;

    .back-button,
    &--title {
      display: none;
    }

    ul {
      margin: 0 -10px;
      padding: 0;
      width: calc(100% + 20px);
      display: flex;
      flex-wrap: wrap;
    }

    &--item {
      list-style: none;
      margin: 10px;
      width: calc(50% - 20px);
      min-height: 135px;
      padding: 16px 10px 10px;
      text-align: center;
      background: #ebebeb;
      background: linear-gradient(
        180deg,
        rgba(235, 235, 235, 1) 0px,
        rgba(235, 235, 235, 1) 135px,
        rgba(255, 255, 255, 0) 135px
      );

      p {
        font-size: 14px;
        margin: 5px 0 12px;
      }

      .heading {
        font-size: 16px;
        font-family: "VodafoneRegularBold";
      }
    }

    &--image {
      width: 105px;
      height: 105px;
      margin: auto;

      img {
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

.flex{
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.middle{
  margin-top: auto;
  margin-bottom: auto;
}

.broadband-heading{
  font-size: x-large;
  display: block;
  font-family: "VodafoneRegular";
  font-weight: bolder;
  margin: 10px 0px 10px 0px;
}

.broadband-product{
  margin-right: 10%;
  margin-left: 10%;
}

.dashboard--submenu {
  position: absolute;
  left: 100%;
  width: 100%;
  max-width: 90%;
  margin-left: 5%;
  transition: left 0.4s ease-in-out;

  .dashboard {
    background-color: transparent;
    padding: 65px 65px 40px;

    .slick {
      &-prev,
      &-next {
        width: 40px;
        height: 40px;

        &:before {
          color: #dedede;
          font-size: 45px;
          opacity: 1;
        }
      }

      &-prev {
        left: -45px;
      }

      &-next {
        right: -40px;
      }
    }
  }

  .slick {
    &-dots {
      bottom: inherit;
      top: 25px;
      right: 30px;
      width: auto;
      text-align: right;

      li {
        width: 6px;
        height: 6px;
        margin: 0 4px;
      }

      button {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        padding: 0;
        margin: 0;
        background-color: #cccccc;

        &:before {
          display: none;
        }
      }
    }

    &-active {
      button {
        background-color: #f60000;

        &:before {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 1360px) {
  .dashboard--submenu {
    max-width: 100%;
    margin-left: 0;
  }

  .submenu {
    &[data-visible="on"] {
      .submenu {
        &__content {
          width: 50vw;
        }

        &__close {
          left: calc(50vw + 30px);
        }
      }
    }

    &[data-level3="on"] {
      .submenu {
        &__level-3 {
          left: calc(50vw - 15px);
        }

        &__close {
          left: calc((50vw + 350px) + 30px);
        }
      }
    }
  }
}

@media only screen and (max-width: 1055px) {
  .submenu {
    top: 88px;
    height: calc(100vh - 88px);
  }

  .dashboard--submenu {
    .dashboard {
      padding: 20px 0;

      .back-button {
        margin-bottom: 20px;
      }

      .slick-prev,
      .slick-next {
        display: none !important;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .submenu {
    top: 52px;
    height: calc(100vh - 52px);

    &__level-2 {
      position: absolute;
      left: -25vw;
      width: 0;
      height: 0;
      transition: left 0.4s ease-in-out;
      overflow-x: hidden;
      border-bottom-right-radius: 12px;

      .spring {
        padding: 0 20px 0 40px;

        &.login {
          padding: 20px;
          height: 100%;

          .back-button {
            margin-bottom: 50px;
          }
        }
      }
    }

    &__close {
      &:hover {
        transform: none;
      }
    }

    &[data-visible="on"] {
      .submenu {
        &__close {
          top: 32px;
        }
      }
    }

    &[data-level2="on"] {
      visibility: visible;

      .submenu {
        &__level-2 {
          width: 50vw;
          height: 100%;
          box-shadow: 0 2px 8px 0 rgba(black, 0.16);
          left: 0;
          z-index: 2;
        }

        .spring {
          padding: 0 20px;
        }

        &__close {
          left: calc(50vw + 30px);
        }

        &__list {
          padding-top: 20px;

          .back-button,
          &--title {
            display: inline-block;
          }

          .back-button {
            padding: 5px 5px 5px 0;

            .icon {
              width: 28px;
              height: 28px;
              cursor: pointer;
            }
          }

          &--title {
            padding: 15px 0 30px;
            font-family: "VodafoneRegularBold";
            font-size: 18px;
          }
        }
      }
    }

    &[data-level3="on"] {
      .submenu {
        &__level-3 {
          width: 50vw;
          left: 0;
          z-index: 2;

          .spring {
            padding: 0 20px;
          }
        }

        &__close {
          left: calc(50vw + 30px);
        }

        &__list {
          padding-top: 20px;

          .back-button,
          &--title {
            display: inline-block;
          }

          .back-button {
            padding: 5px 5px 5px 0;

            .icon {
              width: 28px;
              height: 28px;
              cursor: pointer;
            }
          }

          &--title {
            padding: 15px 0 30px;
            font-family: "VodafoneRegularBold";
            font-size: 18px;
          }
        }
      }
    }

    &__content {
      &--list {
        margin: 0;
        max-width: 100%;
        padding: 0 20px;

        li {
          font-size: 34px;
          line-height: 40px;
        }
      }

      &--sublist {
        padding: 0 20px;

        .back-button {
          left: 20px;
        }
      }

      &--help {
        .icon {
          margin-top: 80px;
        }

        .back-button .icon {
          background-color: transparent;
          width: 28px;
          height: 28px;
          box-shadow: none;
          margin: 0;
        }
      }
    }

    &__mobile {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      > span,
      > .submenu__bottom--list {
        display: block;
      }
    }

    &__bottom {
      &--list {
        list-style: none;
        margin: 0;
        padding: 0 20px 20px;

        li {
          border-top: 1px solid #dedede;
          padding: 7px 0;

          &:last-child {
            border-top: 0;
          }
        }
      }

      &--link {
        display: flex;
        align-items: center;
        font-size: 14px;

        svg {
          margin-right: 10px;
          width: 16px;
          height: 16px;
        }

        &.chevron {
          display: flex;
          justify-content: space-between;
          font-size: 18px;
          padding: 6px 0;

          svg {
            stroke: #e60000;
            margin-right: 0;
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 968px) {
  .broadband-product{
    margin-right: 5%;
    margin-left: 2%;
  }

  .submenu {
    &[data-visible="on"] {
      .submenu {
        &__content {
          width: 55vw;
        }

        &__close {
          left: calc(55vw + 30px);
        }
      }
    }

    &[data-level2="on"] {
      .submenu {
        &__level-2 {
          width: 55vw;
        }
      }
    }

    &[data-level3="on"] {
      .submenu {
        &__level-3 {
          width: 55vw;
        }
      }
    }
  }
}

@media only screen and (max-width: 639px) {
  .submenu {
    top: 48px;
    height: calc(100vh - 38px);

    &__close {
      svg {
        width: 20px;
        height: 20px;
      }
    }

    &__level-2 {
      .spring.login {
        padding: 20px 16px;
      }
    }

    &[data-visible="on"] {
      .submenu {
        &__content {
          width: calc(100% - 56px);
        }

        &__close {
          left: inherit;
          right: 10px;
          top: 24px;
        }
      }
    }

    &[data-level2="on"] {
      .submenu {
        &__level-2 {
          width: calc(100% - 56px);
        }

        &__list {
          &--title {
            font-family: "VodafoneRegular";
            padding: 15px 0 25px;
            font-size: 16px;
          }
        }
      }
    }

    &[data-level3="on"] {
      .submenu {
        &__level-3 {
          width: calc(100% - 56px);
        }

        &__list {
          &--title {
            font-family: "VodafoneRegular";
            padding: 15px 0 25px;
            font-size: 16px;
          }
        }
      }
    }

    &__content {
      &--list {
        padding: 0 16px;

        li {
          font-size: 24px;
          line-height: 30px;
        }
      }
    }
  }
}

@media only screen and (max-height: 800px) {
  .submenu {
    &__content {
      &--list,
      &--sublist {
        justify-content: flex-start;
        padding-top: 120px;
      }
    }
  }
}
