.language {
    width: 236px;
    margin-right: 20px;
    font-size: 16px;
    cursor: pointer;
    text-align: right;
    color: $color-silver;

    &__current {
        display: block;
        position: relative;
        z-index: 1;
        padding: 4px 30px 4px 0;

        &--open {
            background-color: $color-tundora;
        }
    }

    &__chosen {
        display: block;
        text-align: right;
        padding-right: 10px;
    }

    &__icon {
        width: 12px;
        height: 12px;
        position: absolute;
        right: 14px;
        top: 50%;
        transform: translate(0, -50%);

        &--down {
            display: block;
        }

        &--up {
            display: none;
        }
    }

    &__options {
        position: absolute;
        top: 100%;
        width: 236px;
        z-index: 5;
        background-color: $color-abbey;
        padding: 0;
        list-style: none;
        display: none;
        text-align: left;
    }

    &__option {
        display: block;
        padding: 15px 20px;

        &:hover,
        &:focus {
            background-color: $color-white;
            color: $color-tundora;
        }
    }

    &__selector {
        width: 15px;
        height: 15px;
        border: 3px solid $color-dusty-gray;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        position: relative;
        top: -2px;
    }

    &__circle {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: $color-dusty-gray;
        position: absolute;
        top: 2px;
        left: 2px;
        display: none;

        &--selected {
            display: inline-block;
        }
    }
}

@media only screen and (max-width: $breakpoint-desktop) {
    .language {
        width: 100%;
        margin-right: 0;
        text-align: left;
        color: $color-mine-shaft;

        &__options {
            width: 100%;
            position: relative;
            background-color: $color-white;
            padding: 10px 0 10px 20px;
        }

        &__selector {
            border-color: $color-mine-shaft;
            background-color: $color-gallery;
        }

        &__circle {
            background-color: $color-mine-shaft;
        }

        &__current {
            z-index: 1;
            padding: 15px 25px 15px 20px;
            display: inline-block;

            &--open {
                background-color: transparent;

                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    background-color: inherit;
                    top: 100%;
                    bottom: -100%;
                    left: 0;
                    right: 0;
                }
            }
        }

        &__icon {
            right: 14px;
            color: $color-red;
        }

        &__item {
            &--last {
                margin-bottom: 10px;
            }
        }

        &__option {
            padding-top: 10px;
            padding-bottom: 10px;

            &:hover,
            &:focus {
                .language__selector {
                    border-color: $color-tundora;
                }

                .language__circle {
                    border-color: $color-tundora;
                    background-color: $color-tundora;
                }
            }
        }
    }
}
