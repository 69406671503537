.login {
    &__framer {
        overflow: hidden;
        position: relative;
    }

    &__frames {
        width: 300%;
        left: -100%;
        position: relative;
        transition: left 0.4s;

        &--first {
            left: 0;
        }

        &--last {
            left: -200%;
        }
    }

    &__frame {
        width: 33.33%;
        float: left;
        overflow: hidden;
        border: 1px solid $color-mine-shaft;
        transition: visibility 0s;
        transition-delay: 0s;

        &--first,
        &--last {
            visibility: hidden;
            transition-delay: 0.4s;
        }
    }

    &__frames--first &__frame,
    &__frames--last &__frame {
        visibility: hidden;
        transition-delay: 0.4s;
    }

    &__frames--first &__frame--first {
        visibility: visible;
        transition-delay: 0s;
    }

    &__frames--last &__frame--last {
        visibility: visible;
        transition-delay: 0s;
    }

    &__text {
        padding-bottom: 15px;
        border-bottom: 1px solid $color-mine-shaft;
    }
}
