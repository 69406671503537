.time-picker {
    &__time {
        border: 2px solid transparent;
        font-size: 28px;
        line-height: 1em;
        display: block;
        margin: 20px 0;
        font-family: $font-family-vodafone-light;

        &--first {
            margin-top: 0;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__input:checked + &__time {
        border: 2px solid $color-red;
        font-family: $font-family-vodafone-regular;
    }

    &__input:disabled + &__time {
        color: $color-alto;

        &:hover {
            cursor: default;
        }
    }
}

@media only screen and (max-width: $breakpoint-desktop) {
    .time-picker {
        &__time {
            font-size: 24px;
        }
    }
}

@media only screen and (max-width: $breakpoint-tablet) {
    .time-picker {
        &__time {
            font-size: 20px;
            font-family: $font-family-vodafone-regular;
        }
    }
}
