@mixin flush-classes($breakpoint: false) {
    @if $breakpoint {
        @media only screen and (max-width: $breakpoint) {
            $device: "";

            @if $breakpoint == $breakpoint-tablet {
                $device: "sm";
            }

            @if $breakpoint == $breakpoint-desktop {
                $device: "md";
            }

            @if $breakpoint == $breakpoint-tv {
                $device: "lg";
            }

            @include flush-padding($device: "#{$device}-");
        }
    } @else {
        @include flush-padding;
    }
}

@mixin flush-padding($device: "") {
    &--#{$device}all {
        padding: 0 !important;
    }

    &--#{$device}top {
        padding-top: 0 !important;
    }

    &--#{$device}bottom {
        padding-bottom: 0 !important;
    }

    &--#{$device}left {
        padding-left: 0 !important;
    }

    &--#{$device}right {
        padding-right: 0 !important;
    }
}
