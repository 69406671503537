.banner {
  &--red {
    background: #a90000;
    background: linear-gradient(80deg, #a90000, #e60000 55%, #ff0000);
    color: white;

    .heading {
      color: white;      
    }
  }

  &--small {
    height: 240px;

    .banner {
      &__image {
        img {
          height: 240px;
          width: auto;
        }
      }

      &__content {
        max-width: 50%;
        padding-right: 15%;
      }
    }
  }

  &__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__content {
    .heading {
      line-height: 1.25;
      margin-bottom: 24px;

      &--bold {
        font-family: 'VodafoneRegularBold';        
      }      
    }

    .button {
      &--secondary,
      &--secondary:hover {
        background-color: transparent;
        padding: 0;
        font-size: 18px;
      }

      &.has--chevron {
        .icon {
          width: 24px;
          height: 24px;
          margin-left: 4px;
        }
      }
    }
  }
}

@media only screen and (max-width: 968px) {
  .banner {
    &__content {
      padding-right: 0;
    }
  }
}

@media only screen and (max-width: 639px) {
  .banner {
    &--small {
      height: 160px;

      .banner {
        &__image {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);

          img {
            height: 160px;
          }
        }

        &__content {
          max-width: 75%;
        }
      }
    }

    &__container {
      position: relative;
      height: 100%;
    }

    &__content {
      .heading {
        font-size: 20px;
        margin-bottom: 16px;
      }

      .button {
        &--secondary {
          width: auto;
          display: inline-block;
          margin-bottom: 0;
          font-size: 16px;
        }
      }
    }
  }
}
