.range-slider {
    display: block;
    position: relative;
    text-align: center;

    &__heading {
        display: block;
        font-size: 20px;
        margin-bottom: 20px;
    }

    &__ticks {
        height: 20px;
    }

    &__tick {
        height: 20px;
        position: absolute;

        &::after {
            bottom: 10px;
            color: $color-dove-gray;
            content: "|";
            font-size: 6px;
            position: absolute;
        }

        &:first-of-type,
        &:last-of-type {
            color: $color-dove-gray;
            font-size: 14px;

            &::after {
                bottom: -5px;
                content: " ";
                position: absolute;
            }
        }

        &:first-of-type {
            left: 0;
        }

        &:last-of-type {
            right: 0;
        }
    }

    &__track {
        background-color: $color-gallery;
        border-radius: 8px;
        border: 1px solid $color-silver;
        height: 7px;
        margin-top: 40px;
        position: relative;
        width: 100%;
    }

    &--horizontal {
        width: 100%;
    }

    &__fill {
        background-color: $color-seance;
        border-radius: 8px;
        height: 100%;
        position: absolute;
    }

    &__handle {
        background: $color-white;
        border-radius: 50%;
        border: 1px solid $color-silver;
        box-shadow: 0 6px 16px -4px $color-dove-gray;
        cursor: pointer;
        height: 28px;
        position: absolute;
        transition: background-image 0.5s ease;
        top: 50%;
        transform: translate(-1px, -50%);
        width: 28px;
        z-index: 1;
    }

    &__input:focus + &__track &__handle {
        outline: 2px solid $color-cerulean;

        .mouse-active & {
            outline: none;
        }
    }

    &__handle-hover {
        color: $color-seance;
        font-size: 24px;
        position: absolute;
        top: -33px;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
    }

    &__value {
        font-size: 28px;
    }

    &__value-unit {
        font-size: 18px;
    }
}

@media only screen and (max-width: $breakpoint-desktop) {
    .range-slider {
        &__handle-hover {
        }

        &__value {
            font-size: 24px;
        }

        &__value-unit {
            font-size: 20px;
        }
    }
}

@media only screen and (max-width: $breakpoint-tablet) {
    .range-slider {
        &__heading {
            font-size: 18px;
        }

        &__handle-hover {
            top: -34px;
        }

        &__value {
            font-size: 20px;
        }

        &__value-unit {
            font-size: 16px;
        }

        &__tick {
            &:first-of-type,
            &:last-of-type {
                font-size: 11px;
            }
        }
    }
}
