.card {
  display: flex;
  flex-direction: column;

  .heading--bold {
    font-family: "VodafoneRegularBold";
  }

  button:focus {
    outline: none;
  }

  &__item {
    width: 100%;
    background-color: white;
    padding: 16px;
    margin: 10px;
    border-radius: 6px;
    position: relative;

    &.has--shadow {
      box-shadow: 0 2px 8px 0 rgba(black, 0.16);
    }
  }

  &__horizontal {
    flex-direction: row;
    position: relative;

    .card {
      &__content {
        width: 60%;
        text-align: left;
      }

      &__image {
        width: 40%;
        height: auto;
        max-height: 250px;
        margin-bottom: 0;
      }

      &--title {
        flex-grow: 1;

        .heading {
          display: flex;
          height: 100%;
          align-items: center;
          padding: 0;
        }
      }
    }
  }

  &__image {
    min-height: 135px;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 20px;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    &--fit {
      img {
        object-fit: contain;
      }
    }

    &--small {
      min-height: 135px;

      img {
        height: 135px;
      }
    }

    &--large {
      min-height: 320px;

      img {
        height: 320px;
      }
    }

    &--background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      border-radius: 6px;
      overflow: hidden;

      img {
        object-fit: cover;
        height: 100%;
      }

      & + .card__content {
        z-index: 1;
        color: white;

        .heading {
          color: white;
        }
      }
    }
  }

  &__content {
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .heading {
      font-family: "VodafoneRegular";

      &--bold {
        font-family: "VodafoneRegularBold";
      }
    }
  }

  &.has--label {
    &.card__horizontal {
      .card__content {
        height: auto;
        padding: 48px 20px 0 0;
      }
    }
  }

  &.has--background {
    background: #a90000;
    background: linear-gradient(66deg, #a90000, #e60000 55%, #ff0000);
    color: white;

    .heading {
      color: white;
    }

    .button--text {
      color: white;
    }
  }

  &__horizontal {
    &.has--image {
      .card {
        &__content {
          height: auto;
          padding: 0 20px 0 8px;
        }
      }
    }

    &.has--background {
      .card {
        &__content {
          height: auto;
          padding: 0 20px 0 8px;
        }
      }
    }
  }

  &--label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 6px 32px 6px 16px;
    background-color: #007e95;
    color: white;
    border-radius: 0 20px 20px 0;
    font-size: 20px;
    line-height: 28px;
  }

  &--title {
    margin-bottom: 8px;

    .heading {
      font-size: 28px;
      line-height: 34px;
    }

    &.has--icon {
      display: flex;
      flex-direction: row;
      align-items: center;

      .heading {
        margin-left: 8px;
      }
    }

    &.has--background {
      min-height: 135px;
      background: #a90000;
      background: linear-gradient(66deg, #a90000, #e60000 55%, #ff0000);
      color: white;
      border-radius: 6px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-bottom: 20px;

      .heading {
        color: white;
        flex: 0;
        font-size: 40px;
        line-height: 48px;
      }

      .footnote {
        margin: 0;
        font-size: 14px;
      }
    }
  }

  &--description {
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    margin: 0 0 8px 0;
  }

  &--price {
    margin-bottom: 8px;
    font-size: 20px;
  }

  &--button {
    appearance: none;
    border: 1px solid transparent;
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
    padding: 8px 20px;
    border-radius: 6px;
    min-width: 145px;

    &.primary {
      color: #ffffff;
      background-color: #e60000;
    }

    &.secondary {
      color: #ffffff;
      background-color: #666666;
    }

    &.button--text {
      padding: 8px 0 0;
      color: #e60000;
      font-size: 18px;

      .icon {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__item--dashboard {
    .card {
      &__content,
      &--description {
        text-align: left;
      }

      &--title {
        .heading {
          font-size: 18px;
          line-height: 24px;
        }
      }

      &--price {
        font-family: "VodafoneRegular";
        font-size: 18px;
        margin-bottom: 0;

        &--description {
          font-size: 14px;
          color: #666666;
          margin: 0;
        }
      }

      &--bottom {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      &--button {
        &.primary {
          background-color: transparent;
        }

        font-size: 16px;
        padding: 8px 10px 0 0;
        min-width: auto;
      }
    }

    &.card__item--column {
      .card {
        &--price {
          font-family: "VodafoneLight";
          font-size: 56px;

          &--description {
            margin: 4px 0 0;
          }
        }

        &--bottom {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }

  &__slider--content {
    margin-top: 16px;

    .heading {
      font-size: 56px;
      line-height: 1.1;

      span {
        font-size: 18px;
        margin-left: 8px;
        font-family: "VodafoneRegular";

        &.unit {
          font-size: 40px;
          margin-left: 4px;
          font-family: "VodafoneLight";
        }
      }
    }

    .progress {
      margin: 4px 0;
      height: 6px;
      position: relative;
      background: #cccccc;
      border-radius: 5px;
      overflow: hidden;
    }
  }

  &__services {
    li {
      display: inline-block;
      margin: 0 8px;
    }

    svg {
      width: 32px;
      height: 32px;
    }
  }

  &__item--column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .card {
      &__content {
        justify-content: inherit;
        height: auto;
      }

      &__image {
        min-height: 320px;
        margin-bottom: 32px;
      }

      &--description {
        flex-grow: inherit;
      }
    }

    & > .card--title {
      margin-bottom: 24px;

      .heading {
        text-align: center;
        font-size: 24px;
        font-family: "VodafoneLight";
      }
    }
  }

  &__item--plan {
    padding: 0;
    margin: 30px 10px 10px;
    position: relative;

    &.plan__highlight {
      border: 1px solid #007c92;
      border-radius: 0 6px 6px;
    }

    .highlight {
      position: absolute;
      top: -30px;
      left: -1px;
      height: 30px;
      padding: 4px 16px 0;
      border-radius: 6px 6px 0 0;
      background-color: #007c92;
      color: white;
      font-size: 16px;
      line-height: 22px;
      font-family: "VodafoneRegularBold";
    }

    .plan__header {
      display: flex;
      align-items: center;
      padding: 16px;

      .icon {
        margin-right: 12px;
        min-width: 36px;
        height: 36px;
      }

      &--price {
        span {
          font-family: "VodafoneRegular";
          font-size: 18px;
        }
      }
    }

    .button {
      &--details {
        padding: 0;
        font-size: 18px;
        line-height: 24px;
        background-color: transparent;

        .icon {
          width: 24px;
          height: 24px;
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }

    .plan__content {
      height: 100%;
      position: relative;
    }

    .plan__options {
      ul {
        list-style: none;
        padding: 0 6px;
        margin: 0;
      }

      li {
        display: flex;
        align-items: center;
        padding: 16px 0;
        font-size: 18px;

        .icon {
          width: 24px;
          height: 24px;
          margin: 0 20px 0 0;
        }
      }
    }

    .plan__section {
      padding: 16px;

      p {
        margin: 0;
        font-size: 18px;
      }

      .card__services {
        margin: 16px 0 8px;

        ul {
          margin: 0;
        }

        li {
          width: 40px;

          &:first-child {
            margin-left: 0;
          }
        }

        .icon {
          width: 40px;
          height: 40px;
          margin: 0;
        }
      }

      .card--button {
        margin: 40px 0 24px;
        width: 100%;
        text-align: center;
      }

      &--border {
        border-top: 1px solid #ebebeb;
      }

      &--light-grey {
        background-color: #f4f4f4;
      }

      &--grey {
        background-color: #ebebeb;
      }

      &--input {
        position: relative;

        label {
          margin: 0;
          font-size: 18px;
          padding-left: 32px;
          cursor: pointer;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none;

          .icon {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 20px;
            height: 20px;
            padding: 4px;
            margin: 0;
            background-color: white;
            stroke: white;
            stroke-width: 15px;
            border-radius: 3px;
          }
        }

        input {
          opacity: 0;
          position: absolute;
          left: 0;
          top: 0;
          width: 20px;
          height: 20px;
          margin: 0;

          &:checked + label .icon {
            background-color: #007c92;
            stroke: white;
          }
        }
      }
    }

    .icon--mid {
      stroke-width: inherit;
    }

    &.card__plan--compare {
      .plan {
        &__content {
          padding-bottom: 55px;
        }

        &__section:last-child {
          position: absolute;
          bottom: 0;
          width: 100%;
          border-radius: 0 0 6px 6px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .card__item--plan {
    margin: 10px;

    &.plan__highlight {
      margin: 40px 10px 10px;
    }

    &.card__plan--compare {
      .plan {
        &__content {
          padding-bottom: 0;
          display: flex;
          flex-wrap: wrap;
        }

        &__section {
          &:last-child {
            position: relative;
            bottom: inherit;
          }
        }
      }
    }

    .plan {
      &__header {
        padding: 24px 16px;
        cursor: pointer;
      }

      &__section {
        flex-basis: 100%;
      }

      &__options,
      &__details {
        flex-basis: 50%;
      }

      &__details {
        display: flex;
        flex-direction: column;

        .card--button {
          order: 1;
          margin: 0 0 68px;
        }

        .button--details {
          order: 2;
          text-align: left;
        }
      }

      &__services {
        padding: 28px 16px;
        display: flex;
        align-items: center;

        p {
          min-width: 60%;
          padding-right: 16px;
        }

        .card__services {
          margin: 8px 0;
        }
      }

      &__header {
        .icon--accordion {
          min-width: 24px;
          height: 24px;
          color: #fe0000;
          margin: 0;
        }

        .heading {
          display: flex;
          justify-content: space-between;
          padding-right: 20px;
          font-size: 24px;
          margin: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 968px) {
  .card {
    &--title {
      .heading {
        font-size: 24px;
        line-height: 30px;
        padding: 0 8px;
        min-height: auto;
      }
    }

    &__horizontal {
      .card {
        &__image {
          max-height: 185px;
        }
      }
    }
  }
}

@media only screen and (max-width: 639px) {
  .card {
    &--title {
      .heading {
        font-size: 20px;
        line-height: 28px;
      }
    }

    &--description {
      font-size: 16px;
      margin: 16px 0;
    }

    &--price {
      margin-bottom: 16px;
    }

    &__horizontal {
      .card {
        &__content {
          width: 100%;
          padding: 0 16px;
        }

        &__image {
          height: 135px;
        }
      }

      &.has--image {
        .card__content {
          padding: 0 16px 0 0;
        }
      }
    }

    &__slider--content {
      .heading {
        font-size: 42px;

        span {
          font-size: 16px;

          &.unit {
            font-size: 28px;
          }
        }
      }
    }

    &__item--plan {
      .plan {
        &__header {
          padding: 16px;

          .heading {
            justify-content: left;
            font-size: 20px;
            flex-direction: column;
          }
        }

        &__options,
        &__details {
          flex-basis: 100%;
        }

        &__details {
          padding: 16px 16px 24px;

          .button--details {
            order: 1;
            font-size: 18px;
          }

          .card--button {
            order: 2;
            margin: 0;
            font-size: 18px;
          }
        }

        &__options {
          padding: 8px 16px;

          li {
            padding: 12px 0;
            font-size: 16px;
            border-bottom: 1px solid #ebebeb;

            &:last-child {
              border-bottom: 0;
            }
          }
        }

        &__section {
          &--input {
            label {
              font-size: 16px;
            }
          }
        }

        &__services {
          padding: 16px;
          flex-direction: column;
          align-items: flex-start;

          p {
            min-width: 100%;
            padding-right: 0;
            font-size: 16px;
          }

          .card__services {
            margin: 16px 0;
          }
        }
      }
    }
  }
}

.dummy__card--no-margin {
  margin: 0;
}
