.filter-tiles {
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 64px 0 20px 0;

    h3 {
      width: auto;
      padding: 0;
    }

    &--link {
      color: #e60000;
      font-size: 18px;
      font-family: "VodafoneRegular";
      cursor: pointer;
    }
  }

  &__container {
    text-align: center;
    padding: 20px 0 30px;
    margin: 0;
  }

  &__option {
    display: inline-block;
    margin: 0 6px;
    vertical-align: middle;
    text-align: center;

    &--button {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 10px 16px;
      border-radius: 20px;
      border: none;
      cursor: pointer;
      outline: none;
      background-color: #fff;
      line-height: 1.15;

      .icon {
        width: 18px;
        height: 18px;
      }

      &--active {
        background-color: #007c92;
        color: #fff;
      }
    }

    .icon--extra-small {
      vertical-align: bottom;
    }

    .icon--stroke-thin {
      stroke-width: 1px;
    }
  }

  &__button {
    &--text {
      font-size: 18px;
      padding-left: 12px;
      padding-right: 4px;
    }
  }

  .cards {
    display: flex;
    width: calc(100% + 20px);
    margin: 0 -10px;
    padding-bottom: 64px;

    &__item {
      position: relative;
      flex: 0 0 50%;
      height: calc(100% - 20px);
      margin: 10px;
      padding: 20px;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__background {
      &--white {
        background-color: #fff;
      }

      &--wild-sand {
        background-color: #f4f4f4;
      }
    }

    &__column {
      flex: 0 0 33%;

      &--wrap {
        display: flex;
        flex-wrap: wrap;
        flex-basis: 67%;

        .cards {
          &__item {
            flex: 0 0 calc(50% - 20px);
            height: auto;
          }

          &__image-container {
            height: 190px;
          }
        }
      }
    }

    &__image-container {
      height: 409px;
    }

    &__image {
      display: block;
      margin: 0 auto 20px;
      max-width: 100%;
      object-fit: contain;
      border-radius: 6px;

      &--1\/1,
      &--1\/2 {
        max-height: 100%;
      }
    }

    &__title {
      margin: 40px 0 20px 0px;
      font-size: 28px;
      font-family: "VodafoneRegularBold";
      line-height: 1;
      text-align: center;
    }

    &__subtitle {
      font-size: 24px;
      font-family: "VodafoneLight";
      text-align: center;
      margin-bottom: 20px;
    }

    &__link {
      width: 100%;
      text-align: center;
      margin: 24px 0 16px;
    }

    &__no-image {
      .cards {
        &__title,
        &__subtitle,
        &__link {
          text-align: left;
        }
      }
    }

    &__icons {
      .icon {
        margin: 10px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  &--grid3 {
    .cards {
      &__column {
        &--wrap {
          flex-basis: 100%;

          .cards__item {
            flex-basis: calc(33.33% - 20px);
          }
        }
      }

      &__item {
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
      }
    }
  }
}

.my-offers {
  .cards {
    &__column {
      &--wrap {
        flex-basis: 100%;

        .cards__item {
          flex-basis: calc(33% - 20px);
        }
      }
    }

    &__item {
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
    }
  }

  .icon {
    &--extra-thin {
      stroke-width: 1px;
    }
  }
}

// Tablet
@media only screen and (max-width: 968px) {
  .filter-tiles {
    .cards {
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;

      &__column {
        &:not(.cards__column--wrap) {
          flex: 0 0 42%;
        }

        &--wrap {
          flex-basis: 100%;
        }
      }

      &__title {
        font-size: 24px;
      }

      &__subtitle {
        font-size: 20px;
      }

      &__link {
        font-size: 18px;
      }

      &__image-container {
        height: 300px;
      }

      &__column--wrap {
        .cards__image-container {
          height: 190px;
        }
      }

      &__item {
        min-width: 200px;
      }

      &__copy-container {
        white-space: normal;
      }
    }

    &--grid3 {
      .cards {
        &__column--wrap {
          .cards__item {
            flex-basis: calc(50% - 20px);
          }
        }
      }
    }

    .my-offers {
      .cards {
        &__image-container {
          height: 138px;
        }
      }
    }
  }

  .heading--4 {
    font-size: 24px;
  }
}

// Mobile
@media only screen and (max-width: 639px) {
  .filter-tiles {
    &__container {
      padding-top: 12px;
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
    }

    &__button--text {
      font-size: 16px;
    }

    .cards {
      &__column {
        &:not(.cards__column--wrap) {
          width: 80%;
          flex: 0 0 75%;
        }

        &--wrap {
          flex-basis: 160%;
        }
      }

      &__image-container {
        height: 180px;
      }

      &__column--wrap {
        .cards__image-container {
          height: 130px;
        }
      }

      &__title {
        font-size: 20px;
      }

      &__subtitle {
        font-size: 18px;
      }
    }

    &--grid3 {
      .cards {
        &__column--wrap {
          flex-basis: 100%;

          .cards__item {
            flex-basis: calc(100% - 20px);
          }

          .cards__image-container {
            height: 175px;
          }
        }

        &__title {
          margin: 32px 0 16px;
        }

        &__subtitle {
          font-size: 16px;
        }
      }
    }

    .my-offers {
      .cards {
        &__column--wrap {
          flex-basis: 100%;
        }

        &__column--wrap .cards__item {
          flex-basis: 100%;
          margin-left: 0;
        }

        &__image-container {
          height: 175px;
        }
      }
    }
  }

  .heading--4 {
    font-size: 22px;
  }
}
