.cart {
  &__details {
    padding-bottom: 80px;

    &--heading {
      padding: 64px 0 40px;
    }
  }

  &__icon {
    &--label {
      position: absolute;
      top: 12px;
      right: 10px;
      width: 16px;
      height: 16px;
      background-color: #e60000;
      border-radius: 50%;

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 12px;
        font-family: "VodafoneRegular";
        color: white;
      }
    }
  }
}

.navigation__item--cart {
  position: relative;

  a.navigation__link--active {
    color: #e60000 !important;

    &::after {
      display: none;
    }
  }
}

.section {
  &--white {
    background-color: white;
  }
}

.heading {
  &--regular {
    font-family: "VodafoneRegular";
  }

  &--bold {
    font-family: "VodafoneRegularBold";
  }
}

@media only screen and (max-width: 1000px) {
  .cart {
    &__icon {
      &--label {
        top: 8px;
        right: 4px;
      }
    }
  }
}

@media only screen and (max-width: 639px) {
  .cart {
    &__details {
      &--heading {
        padding: 55px 0 32px;
        margin: 0;
      }
    }

    &__icon {
      &--label {
        top: 6px;
        right: 2px;
      }
    }
  }
}
