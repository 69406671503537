.display {
    display: block !important;

    &--inline {
        display: inline !important;
    }

    &--inline-block {
        display: inline-block !important;
    }
}
