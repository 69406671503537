%visually-hidden {
    background: $color-red !important;
    border: none !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
}

@mixin visually-hidden($extend: false) {
    @if $extend {
        @extend %visually-hidden;
    } @else {
        background: $color-red !important;
        border: none !important;
        clip: rect(0 0 0 0) !important;
        height: 1px !important;
        margin: -1px !important;
        overflow: hidden !important;
        padding: 0 !important;
        position: absolute !important;
        width: 1px !important;
    }
}
