.call-us {
    &__card {
        padding: 40px 80px;
    }

    &__open {
        color: $color-green;
    }

    &__number {
        vertical-align: middle;
    }

    &--waiting {
        color: $color-dove-gray;
    }

    &__background {
        background-color: $color-gallery;
    }

    &__card-narrow {
        padding: 20px 90px;
    }
}

@media only screen and (max-width: $breakpoint-desktop) {
    .call-us {
        &__card-narrow {
            padding: 20px 40px;
        }
    }
}

@media only screen and (max-width: $breakpoint-tablet) {
    .call-us {
        &__card {
            padding: 25px 10px;
        }

        &__card-narrow {
            padding: 25px 0;
        }

        &__background {
            background-color: $color-white;
        }
    }
}
