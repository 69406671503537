.show-more {
    &__heading {
        border-top: 1px solid $color-silver;
        padding: 15px;
        width: 100%;
    }

    &__heading--active &__heading-more {
        display: none;
    }

    &__heading--active &__heading-less {
        display: inline;
    }

    &__heading-text,
    &__heading-chevron {
        display: inline-block;
        vertical-align: middle;
    }

    &__heading-chevron {
        color: $color-red;
        margin-left: 5px;
        transition: transform 0.3s;
    }

    &__heading--active &__heading-chevron {
        transform: scaleY(-1);
    }

    &__heading-less {
        display: none;
    }

    &__content {
        background-color: $color-wild-sand;
        padding: 30px;
    }
}

.js .show-more {
    &__content {
        &--collapse {
            display: none;
        }
    }
}

@media only screen and (max-width: $breakpoint-tablet) {
    .show-more {
        &__content {
            padding: 20px;
        }
    }
}
