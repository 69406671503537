.sayt {
    &__category {
        text-transform: capitalize;
    }

    &__listing {
        margin: -10px;
        overflow: hidden;
        text-align: left;
        transition: height 0.5s;
    }

    &__item {
        background-color: $color-white;
        margin: 10px;
    }

    &__heading {
        display: inline-block;
        background-color: $color-silver-chalice;
        color: $color-mine-shaft;
        padding: 11px 37px;
        text-align: center;
        font-size: 20px;
        margin-top: 20px;

        &--hide {
            display: none;
        }
    }

    &__content {
        height: 0;
        overflow: hidden;
    }

    &__see-more {
        display: inline-block;
    }

    &__see-less {
        display: none;
    }

    &__heading--expanded &__see-more {
        display: none;
    }

    &__heading--expanded &__see-less {
        display: inline-block;
    }
}

@media only screen and (max-width: $breakpoint-tablet) {
    .sayt {
        &__heading {
            display: block;
        }

        &__item {
            margin: 5px;
        }
    }
}
