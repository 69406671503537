.search-snack {
    margin: 0 auto;
    text-align: center;
    width: 100%;

    &__form {
        padding: 5px 40px;
        position: relative;
    }

    &__popular-tags {
        margin: -10px;
        text-align: center;
    }

    &__popular-tag {
        background: $color-silver;
        border-radius: 3px;
        color: $color-black;
        display: inline-block;
        font-size: 20px;
        line-height: normal;
        margin: 10px;
        padding: 13px 20px;
        text-align: center;

        &:hover {
            background: $color-white;
            text-decoration: underline;
        }
    }

    &__input {
        width: 580px;
        margin: 0 auto;

        &--round {
            border-radius: 25px;
        }
    }
}

@media only screen and (max-width: $breakpoint-desktop) {
    .search-snack {
        &__input {
            width: 480px;
        }
    }
}

@media only screen and (max-width: $breakpoint-tablet) {
    .search-snack {
        &__input {
            width: 100%;
        }
    }
}
