.brand-outline {
    position: relative;
    z-index: 1;
    display: inline-block;
    font-size: 27px;

    &--white {
        color: $color-white;
    }

    &--red {
        color: $color-red;
    }

    &__icon {
        height: to-em(250px);
        width: to-em(220px);
        stroke: currentColor;
        margin: to-em(30px);
        stroke-width: 7px;
    }

    &__text {
        font-family: $font-family-vodafone-regular-bold;
        font-size: to-em(45px);
        top: to-em(40px);
        position: absolute;
        font-weight: 300;
        left: -14px;
        letter-spacing: -2px;
    }

    &--reversed &__text {
        left: auto;
        right: -14px;
    }
}

@media only screen and (max-width: $breakpoint-desktop) {
    .brand-outline {
        font-size: 23px;
    }
}

@media only screen and (max-width: $breakpoint-tablet) {
    .brand-outline {
        font-size: 18px;
    }
}
