.login {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  background: white;
  transition: left 0.4s ease-in-out;
  height: auto;

  &.spring {
    padding: 15vh 0 0;
  }

  form {
    position: relative;
    min-height: 210px;
  }

  &__step {
    // visibility: hidden;
    position: absolute;
    width: 100%;
    // z-index: -1;
    right: -100%; //
    transition: right 0.4s ease-in-out; //

    &[data-visible="on"] {
      visibility: visible;
      z-index: 1;
      right: 0; //
    }
  }

  &__form {
    box-shadow: none;
    padding: 16px 0 0;
    overflow: hidden;
    //height: calc(100% - 15vh);
  }

  &__card {
    margin-top: 20px;
  }

  input {
    border-radius: 6px;
  }

  &__suggestion {
    text-align: center;
    font-size: 14px;
    margin: 16px 0;

    a {
      text-decoration: underline;
    }
  }
}

input {
  &.form__input {
    border-radius: 5px;
    box-shadow: none;
    font-family: "VodafoneRegular";

    &:focus {
      outline: 0px;
    }
  }
}

button {
  font-family: "VodafoneRegular";

  &.button {
    border-radius: 5px;

    &:focus {
      outline: 0px;
    }
  }

  &__card {
    &--image {
      width: 100%;
      margin-top: 40px;
      border-radius: 10px;
    }
  }
}

.form {
  &__checkbox {
    display: block;
    font-size: 16px;
    width: auto;
    margin: -16px 0 40px;
    padding-right: 40%;

    &__input {
      position: absolute;
      height: 1px;
      width: 1px;
      margin: -1px;
      padding: 0;
      overflow: hidden;
      border: 0;
      clip: rect(0 0 0 0);
    }

    &__label {
      cursor: pointer;
      position: relative;
      line-height: 18px;

      &:before {
        content: "";
        height: 18px;
        width: 18px;
        margin-right: 8px;
        float: left;
        border: 1px solid #999;
        border-radius: 3px;
        background-color: #fff;
        transition: 0.15s all ease-out;
      }
    }

    &__icon svg {
      color: #fff;
      stroke-width: 20px;
      width: 10px;
      height: 0;
      position: absolute;
      left: 4px;
      top: 4px;
    }

    &__input:checked:enabled + .form__checkbox__label:before {
      background-color: #007c92;
      border-color: #007c92;
    }

    &__input:checked + .form__checkbox__label > .form__checkbox__icon svg {
      color: #fff;
      height: 10px;
    }
  }

  &__label {
    &--required {
      &:after {
        display: none;
      }
    }
  }

  &__link--right {
    position: absolute;
    right: 0;
    top: 0;
    line-height: 18px;

    a {
      text-decoration: underline;
    }
  }

  &__label--error {
    &.small {
      font-size: 12px;
      color: #e60000;
      padding-left: 16px;
      margin-bottom: 36px;
    }
  }

  &__row {
    position: relative;

    .form__label {
      position: absolute;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
      left: 20px;
      transition: all 0.3s ease;
    }
  }

  &__label {
    &.focus {
      color: #007c92;
    }

    &.form__label--error {
      & + span {
        input {
          border: 1px solid #e60000;

          &:focus {
            border: 1px solid #e60000;
          }
        }
      }
    }
  }
}

.spring label {
  width: 100%;
  font-weight: normal;
  position: relative;
}

label {
  &.on-focus {
    .form__label {
      top: -12px;
      left: 0;
      transform: translateY(0) scale(0.75);
      background-color: white;
      padding: 0 8px;
    }

    span {
      input {
        &.form__input {
          &:focus {
            border: 1px solid #007c92;
          }
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          box-shadow: 0 0 0 50px white inset !important;
        }
      }

      &.form__label--error {
        color: #e60000;

        & + span {
          input {
            border: 1px solid #e60000;

            &:focus {
              border: 1px solid #e60000;
            }
          }
        }
      }
    }
  }
}

.heading {
  &--2 {
    font-size: 40px;
    margin: 24px 0 32px;
  }
}

@media only screen and (max-width: 639px) {
  .login.spring {
    padding: 15vh 20px 0;
  }
}

@media only screen and (max-width: 450px) {
  .form {
    &__link--right {
      font-size: 12px;
      line-height: 40px;
    }

    &__field--error {
      .small {
        margin-bottom: 24px;
      }
    }

    &__checkbox {
      margin-bottom: 24px;
    }
  }
}
