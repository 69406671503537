h1,
h2,
h3,
h4,
h5,
h6 {
  font: inherit;
}

.heading {
  display: block;
  font-family: $font-family-vodafone-light;
  font-weight: 400;
  line-height: 1em;
  width: 100%;
  letter-spacing: 0;
  margin: 0;

  &--light {
    font-family: $font-family-vodafone-light !important;
  }

  &--regular {
    font-family: $font-family-vodafone-regular !important;
  }

  &--bold {
    font-family: $font-family-vodafone-regular-bold !important;
  }

  &--3 {
    font-size: 40px;
    line-height: 48px;
  }

  &--4 {
    font-size: 28px;
    line-height: 34px;
  }
}

@media only screen and (max-width: $breakpoint-desktop) {
  .heading {
    &--4 {
      font-size: 28px;
      line-height: 34px;
      margin: 15px 0;
    }
  }
}

@media only screen and (max-width: $breakpoint-tablet) {
  .heading {
    &--4 {
      font-size: 20px;
      line-height: 28px;
      margin: 15px 0;
    }
  }
}
