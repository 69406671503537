.accessory {
    background-color: $color-white;
    box-shadow: 0 3px 5px $color-silver;
    margin: 1px 0 5px;
    position: relative;
    text-align: center;

    &__heading {
        margin: 0;
    }

    &__brand {
        display: block;
    }

    &__content {
        padding: 30px 20px 40px;
        text-align: center;
    }

    &__button {
        display: block;
    }

    &__image-container {
        height: 300px;
        margin: 30px 0;
        position: relative;
    }

    &__image {
        @include position;
        width: auto;
        max-height: 100%;
    }

    &__was {
        color: $color-red;
        margin-top: 0;
        margin-bottom: 0;
    }

    &__strikethrough {
        text-decoration: line-through;
    }

    &__rating-price-container {
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: $breakpoint-desktop) {
    .accessory {
        &__content {
            padding-bottom: 30px;
        }

        &__image-container {
            margin-bottom: 40px;
        }
    }
}

@media only screen and (max-width: $breakpoint-tablet) {
    .accessory {
        &__content {
            padding: 25px 10px;
        }

        &__image-container {
            height: 150px;
        }

        &__button {
            margin: 0;
        }
    }
}
