.coupon {
  width: 100%;
  z-index: 9;
  padding: 10px 0px;

  .spring {
    position: relative;
  }

  &__container {
    max-width: 610px;
    margin: auto;
  }

  &__form {
    display: flex;
    position: relative;
    padding: 20px 0;

    input {
      width: calc(100% - 80px);
      padding: 12px 20px;
      height: 52px;
      border-radius: 6px 0 0 6px;
      border: 1px solid #999999;

      &:focus,
      &:focus-visible {
        border: 2px solid #007c92;
      }
    }

    button {
      padding: 10px;
      height: 52px;
      width: 80px;
      border-radius: 0 6px 6px 0;
      background-color: #e60000;

      svg {
        width: 26px;
        height: 26px;
        stroke: white;
      }

      &:hover {
        background-color: #990000;

        svg {
          stroke: white;
        }
      }

      &[disabled],
      &[disabled]:hover {
        background-color: #cccccc;
      }

      &[type="reset"] {
        position: absolute;
        background-color: transparent;
        right: 80px;
        width: 52px;

        svg {
          width: 18px;
          height: 18px;
          stroke-width: 5px;
          stroke: #333333;
        }

        &[disabled] {
          opacity: 0;
        }
      }
    }
  }

  &-results {
    .cart__summary {
      border: 0;

      &--title {
        .title--details {
          display: none;
        }
      }

      &--subtitle {
        margin-top: 8px;

        button {
          color: #e60000;
          font-size: 18px;

          svg {
            margin-left: 8px;
          }
        }
      }
    }
    .summary {
      &__card {
        box-shadow: none;
        margin: 20px;

        &--left {
          svg {
            stroke-width: inherit;
            width: 36px;
            height: 36px;
          }
        }

        &--right {
          svg {
            stroke: #e60000;
            width: 30px;
            height: 30px;
          }
        }

        &--bottom {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .coupon {
    padding: 0;
  }
}

@media only screen and (max-width: 1000px) {
  .coupon {
    &__container {
      max-width: 100%;
      padding: 0;
    }
    &__form {
      padding: 10px 0;
    }
  }
}

@media only screen and (max-width: 640px) {
  .coupon {
    &__container {
      padding: 0 10px !important;
    }
    &__form {
      padding: 10px 0;
    }
  }
}
