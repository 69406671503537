.accordion {
  list-style: none;
  padding: 0;
  color: $color-black;
}

@media only screen and (max-width: 1055px) {
  .accordion__heading--active .accordion__chevron {
    -ms-transform: scaleY(-1);
    transform: scaleY(-1);
  }
}

@media only screen and (max-width: $breakpoint-desktop) {
  .accordion {
    &__heading {
      font-size: 20px;
      padding: 25px 30px;

      &--slim {
        padding: 14px 30px 15px;
      }
    }

    &__content {
      padding: 25px 15px;
      margin-right: 15px;
      margin-left: 15px;

      &--slim {
        padding: 18px 30px 19px;
      }

      &--collapse {
        display: none;
      }
    }

    &--spring {
      .accordion__heading {
        padding: 20px;
      }
      .accordion__content {
        margin-right: 0;
        margin-left: 0;
        padding: 20px;
      }
    }
  }
}
