// $font-path: "../fonts/group/" !default;
// $font-path-vodafone: $font-path + "vodafone/";

// $font-lang-sets: ("gre/", "U+1F00-1FFF, U+0370-03FF"),
//   ("ara/", "U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+E0FF, U+EFFD, U+F000, U+FB50-FDFF, U+FE80-FEFC"),
//   (
//     "lat/",
//     "U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2212, U+2215"
//   );

$font-family-vodafone-light: "VodafoneLight";
// $font-filename-vodafone-light: "vodafone-light";

$font-family-vodafone-regular: "VodafoneRegular";
// $font-filename-vodafone-regular: "vodafone-regular";

$font-family-vodafone-regular-bold: "VodafoneRegularBold";
// $font-filename-vodafone-regular-bold: "vodafone-bold";

$font-family-sans-serif: $font-family-vodafone-regular, Helvetica, Arial, sans-serif;
$font-family-serif: Georgia, "Times New Roman", Times, serif;
$font-family-monospace: "Fira Mono", Menlo, Monaco, Consolas, "Courier New", monospace;

// $font-list-vodafone: ($font-family-vodafone-light, $font-filename-vodafone-light),
//   ($font-family-vodafone-regular, $font-filename-vodafone-regular),
//   ($font-family-vodafone-regular-bold, $font-filename-vodafone-regular-bold);
