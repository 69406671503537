// Primary colours
$color-red: #e60000;
$color-mine-shaft: #333333;
$color-white: #ffffff;
$color-green: #428600;

// Secondary colours
$color-black: #000000;
$color-red-berry: #990000;
$color-guardsman-red: #bd0000;
$color-seance: #9c2aa0;
$color-amethyst: #c649ca;
$color-finn: #5e2750;
$color-buddha-gold: #a8b400;
$color-supernova: #fecb00;
$color-tangerine: #eb9700;
$color-blue-lagoon: #007c92;
$color-cerulean: #00b0ca;
$color-provincialpink: #fef2f2;
$color-pistachio: #a8b600;

// Monochrome colours
$color-black: #000000;
$color-mine-shaft: #333333;
$color-mine-shaft-light: #3d3d3d;
$color-abbey: #4a4d4e;
$color-ironside-gray: #6f6e6b;
$color-dove-gray: #666666;
$color-dusty-gray: #999999;
$color-silver-chalice: #afafaf;
$color-silver: #cccccc;
$color-mercury-dark: #e4e4e4;
$color-gallery: #ebebeb;
$color-wild-sand: #f4f4f4;
$color-porcelain: #fcfdfd;
$color-white: #ffffff;

// Commonly used throughout
$color-mine-shaft-dark: #2a2a2a;
$color-tundora: #434343;
$color-alto: #d6d6d6;
$color-mercury: #e5e5e5;
$color-limeade: #428600;
