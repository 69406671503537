.float {
    &--left {
        float: left !important;
    }

    &--right {
        float: right !important;
    }

    &--none {
        float: none !important;
    }
}
