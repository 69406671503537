.service-selector {
    $color-half-baked: #80bec9;

    position: relative;
    z-index: 2;
    text-align: center;
    display: block;

    &--circle {
        position: absolute;
        top: 0;
    }

    &--inline,
    &--circle {
        display: inline-block;
    }

    &--inline {
        min-width: 280px;
    }

    &--disabled {
        opacity: 0.7;
    }

    &__trigger {
        border: 1px solid $color-dusty-gray;
        display: block;
        background-color: $color-white;
        color: $color-mine-shaft;
        padding: 10px 20px;
        padding-right: 56px;
        position: relative;
        text-align: left;
        width: 100%;

        &::after {
            @include chevron($scale: 16px);

            content: "";
            margin-top: -12px;
            position: absolute;
            right: 20px;
            top: 50%;
        }
    }

    &--circle &__trigger {
        border: none;
        display: inline-block;
        background: none;
        padding: 0;
        text-align: center;

        &::before {
            content: "";
            background: $color-mine-shaft;
            border-radius: 50%;
            border: 2px solid $color-white;
            box-shadow: 0 2px 4px hex-to-rgba($color-black, 0.3);
            height: 20px;
            position: absolute;
            right: -5px;
            top: 22px;
            width: 20px;
            z-index: 1;
        }

        &::after {
            height: 7px;
            margin: 0;
            right: 3px;
            top: 28px;
            width: 7px;
            z-index: 1;
            color: $color-white;
        }
    }

    &--circle#{&}--active &__trigger {
        &::before,
        &::after {
            content: none;
        }
    }

    &--disabled &__trigger {
        background-color: $color-blue-lagoon;
        color: $color-white;
        border-color: $color-half-baked;
    }

    &__input:focus + &__select-button {
        outline: 2px solid $color-cerulean;

        .mouse-active & {
            outline: none;
        }
    }

    &__active-service {
        position: relative;
    }

    &__active-service-image {
        display: inline-block;
        width: 50px;
        height: 50px;
        vertical-align: middle;
        margin-right: 10px;
        border-radius: 50%;
        position: relative;

        &--icon {
            padding: 5px;
            color: $color-white;
            background: $color-mine-shaft;
        }
    }

    &--circle &__active-service-image {
        height: 65px;
        width: 65px;
        margin: 0 auto;
        border: 2px solid $color-white;
        box-shadow: 0 2px 4px hex-to-rgba($color-black, 0.3);
        display: block;
    }

    &--circle &__service-icon {
        width: 90%;
        height: 100%;
    }

    &__active-heading {
        margin: 0;
        vertical-align: middle;
        display: inline-block;
    }

    &--circle &__active-heading {
        margin-top: 5px;
        line-height: 1;
        font-size: 14px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;

        &::first-line {
            font-size: 18px;
            font-family: $font-family-vodafone-regular-bold;
        }
    }

    &__active-number {
        display: block;
        font-size: 16px;
    }

    &--circle &__active-number {
        display: none;
    }

    &__dropdown {
        background-color: $color-gallery;
        border: 1px solid $color-dusty-gray;
        border-top: none;
        color: $color-dove-gray;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        width: 100%;

        .js & {
            display: none;
        }
    }

    &__list {
        background-color: $color-gallery;
        border: 1px solid $color-dusty-gray;
        color: $color-dove-gray;
    }

    &--fieldset-dropdown &__list {
        background-color: transparent;
        border: none;
    }

    &--circle &__dropdown {
        border-top: 1px solid $color-dusty-gray;
        top: 0;
        left: 100%;
        margin-left: 10px;
        width: 320px;
        background: $color-wild-sand;

        &::before,
        &::after {
            @include arrow($direction: "left", $width: 15px);

            content: "";
            position: absolute;
            top: 18px;
            right: 100%;
            color: $color-dusty-gray;
        }

        &::after {
            margin-right: -1px;
            color: $color-wild-sand;
        }
    }

    &__dropdown-close {
        position: absolute;
        top: 3px;
        right: 10px;
        font-size: 20px;
    }

    &--active &__dropdown {
        display: block;
    }

    &__heading {
        @include clearfix;

        display: none;
        margin: 10px 10px 0;
        padding: 0 10px 10px;
        border-bottom: 1px solid $color-silver;
        position: relative;
    }

    &--circle &__heading {
        display: block;
    }

    &__dropdown-heading {
        color: $color-mine-shaft;
        float: left;
        margin: 0;
        width: auto;
    }

    &__service {
        border-top: 1px solid $color-silver;
        color: $color-mine-shaft;
        display: block;
        padding: 10px 20px;
        text-align: left;

        &--first {
            border-top: none;
            margin-top: 0;
        }

        &--active,
        &:hover {
            color: $color-mine-shaft;
            cursor: pointer;
        }
    }

    &__service-image {
        border-radius: 50%;
        height: 50px;
        margin-right: 10px;
        width: 50px;
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        color: $color-white;
        position: relative;

        &--icon {
            background-color: $color-mine-shaft;
        }

        &--alt {
            background-color: $color-silver;
            color: $color-dove-gray;
        }
    }

    &__select-icon {
        @include position;
        display: none;
        height: 12px;
        width: 12px;
    }

    &__service--selected &__select-icon,
    &__input:checked + &__select-button &__select-icon {
        display: block;
    }

    &__service-info {
        clear: both;
        display: inline-block;
        text-align: left;
        vertical-align: middle;
        width: calc(100% - 88px);

        &--inner {
            width: auto;
        }
    }

    &__service-details {
        font-size: 16px;
    }

    &__select-button {
        background-color: $color-white;
        border: 1px solid $color-mine-shaft;
        border-radius: 50%;
        display: inline-block;
        height: 20px;
        padding: 0;
        position: relative;
        vertical-align: middle;
        width: 20px;
    }

    &__service--selected &__select-button,
    &__input:checked + &__select-button {
        background-color: $color-green;
        border-color: $color-white;
        box-shadow: 0 0 0 1px $color-mine-shaft;
        color: $color-white;
        cursor: default;
        padding: 4px;
    }
}

@media only screen and (max-width: $breakpoint-desktop) {
    .service-selector {
        &--circle {
            margin-top: 20px;
        }
    }
}

@media only screen and (max-width: $breakpoint-tablet) {
    .service-selector {
        &--inline {
            display: block;
            min-width: auto;
        }

        &--circle {
            margin-top: 10px;
        }

        &__service {
            padding: 10px 12px;
        }

        &__dropdown-close {
            top: 0;
        }

        &__trigger {
            padding: 10px 20px 10px 12px;
        }

        &__service-image,
        &__active-service-image {
            width: 40px;
            height: 40px;
        }

        &__trigger {
            &::after {
                right: 16px;
            }
        }

        &__service-info {
            width: calc(100% - 78px);
        }

        &--circle &__services {
            max-height: calc(100vh - 120px);
            overflow-y: auto;
        }

        &--active#{&}--circle {
            z-index: 101;

            &::before {
                content: "";
                background: $color-mine-shaft;
                opacity: 0.8;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                position: fixed;
                z-index: 1;
            }
        }

        &__dropdown {
            left: auto;
            margin-left: 0;
            min-width: auto;
            position: relative;
            right: auto;
            top: auto;
            width: auto;
            z-index: 1;
        }

        &--circle &__dropdown {
            left: 10px;
            position: fixed;
            right: 10px;
            top: 20px;
            width: auto;
            margin-left: 0;

            &::before,
            &::after {
                content: none;
            }
        }
    }
}
