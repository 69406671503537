.bundle-promotion {
    text-align: center;

    &__headline {
        margin-top: 0;
        margin-bottom: 10px;
    }

    &__item {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 20px;
    }

    &__logo {
        max-height: 24px;
        vertical-align: middle;
    }

    &__conjunction {
        padding: 0 20px;
        display: inline-block;
        vertical-align: middle;
    }
}

@media only screen and (max-width: $breakpoint-desktop) {
    .bundle-promotion {
        &__logo {
            max-height: 22px;
        }
    }
}
