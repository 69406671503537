.breadcrumbs {
  $color-red: #E60000;
  $color-black: #000000;
  $color-mine-shaft: #333333;
  $color-abbey: #4A4D4E;
  $color-dove-gray: #666666;
  $color-silver: #CCCCCC;
  $color-white: #FFFFFF;

  background: inherit;
  border-bottom: 1px solid #ebebeb;
  padding: 0;
  text-align: left;
  padding-top: 98px;

  & > .spring {
    padding: 0 15px;
  }

  &--extrude {
    background-color: $color-white;
    color: $color-mine-shaft;
  }

  &--dark {
    background-color: $color-mine-shaft;
    border-color: $color-dove-gray;
    color: $color-silver;
  }

  &--transparent {
    background-color: hex-to-rgba($color-black, .6);
    color: $color-white;
    border-color: hex-to-rgba($color-silver, .4);
    position: relative;
  }

  &--abbey {
    background-color: $color-abbey;
    border-color: $color-dove-gray;
    color: $color-white;
  }

  &__list {
    list-style: none;
    font-size: 14px;
    margin: 13px 0;
    padding: 0;
  }

  &__item {
    display: inline-block;
  }

  &__crumb {
    margin: 0 4px;
    position: relative;

    &--first {
      margin-left: 0;
    }

    &::after {
      content: " ";
      border-bottom: 1px solid transparent;
      border-color: inherit;
      position: absolute;
      bottom: 1px;
      left: 0;
      right: 0;
    }

    @media all and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
      &::after {
        bottom: -2px;
      }
    }

    &--current {
      &::after {
        display: none;
      }
    }
  }

  &__chevron {
    display: inline-block;
    vertical-align: middle;
    color: currentColor;
    width: 24px;
    height: 24px;
  }

  &--style {
    display: inline-block;
    color: white;
    font-family: 'VodafoneLight';
  }

  .chevron {
    display: table;
    position: relative;
    width: 100%;

    &--inline {
      display: block;
    }

    &__text,
    &__container {
      display: table-cell;
      vertical-align: middle;
    }

    &__text {
        font-size: 14px; 
        color: #999999;
        margin: 0 4px;  
        
        &.breadcrumbs__crumb {
          color: $color-mine-shaft;
          margin: 0 4px;
        }

        &--wide {
          width: 100%;
          padding-right: 15px;
        }
    }

    &--inline .chevron__text {
      display: inline;
    }

    &__container {
      line-height: 0;
      text-align: center;
      width: 12px;
      margin: 0 4px;

      &--float {
        float: right;
      }
    }

    &--inline .chevron__container {
      display: inline-block;
    }

    &__icon {
      font-size: 18px;

      &--white {
        color: $color-white;
      }

      &--red {
        color: $color-red;
      }

      &--mine-shaft {
        color: $color-mine-shaft;
      }
    }
  }

  .icon {
    &--size {
      width: 12px;
      height: 12px;
      stroke-width: .4em;
      vertical-align: middle;
      stroke-width: 15px;
    }
  }

  .hide--lg {
    display: none !important;
  }

}

@media only screen and (max-width: 1055px) {
  .breadcrumbs {
    padding-top: 90px;
  }
}

@media only screen and (max-width: 1000px) {
  .breadcrumbs {
    padding-top: 52px;

    & > .spring {
      padding: 0 25px;
    }

    &__chevron {
      transform: none;
    }
  }
}


@media only screen and (max-width: 639px) {
  .breadcrumbs {
    &__crumb {
      order: 2;
    }

    &__chevron {
      order: 1;
    }
    
    .hide {
      &--lg {
        display: flex !important;
        align-items: center;
      }

      &--sm {
        display: none;
      }
    }
  }
}
