.search {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #222222;
  padding: 98px 0;
  z-index: 9;
  animation-name: opacity-in;
  animation-duration: 0.2s;

  .section {
    background-color: white;
    animation-name: slide-in;
    animation-duration: 0.55s;
    border-radius: 0 0 10px 10px;
  }

  .spring {
    position: relative;
  }

  &__container {
    max-width: 610px;
    margin: auto;
    padding-top: 80px;
  }

  &--close {
    position: absolute;
    top: 20px;
    right: 30px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &__form {
    display: flex;
    align-items: center;
    position: relative;
    padding: 60px 0 190px;

    input {
      width: calc(100% - 52px);
      padding: 12px 20px;
      height: 52px;
      border-radius: 6px 0 0 6px;
      border: 1px solid #999999;

      &:focus,
      &:focus-visible {
        border: 2px solid #007c92;
      }
    }

    button {
      padding: 10px;
      height: 52px;
      width: 52px;
      border-radius: 0 6px 6px 0;
      background-color: #e60000;

      svg {
        width: 26px;
        height: 26px;
        stroke: white;
      }

      &:hover {
        background-color: #990000;

        svg {
          stroke: white;
        }
      }

      &[disabled],
      &[disabled]:hover {
        background-color: #cccccc;
      }

      &[type="reset"] {
        position: absolute;
        background-color: transparent;
        right: 52px;

        svg {
          width: 18px;
          height: 18px;
          stroke-width: 5px;
          stroke: #333333;
        }

        &[disabled] {
          opacity: 0;
        }
      }
    }
  }

  &-results {
    .cart__summary {
      border: 0;

      &--title {
        .title--details {
          display: none;
        }
      }

      &--subtitle {
        margin-top: 8px;

        button {
          color: #e60000;
          font-size: 18px;

          svg {
            margin-left: 8px;
          }
        }
      }
    }
    .summary {
      &__card {
        box-shadow: none;
        margin: 20px;

        &--left {
          svg {
            stroke-width: inherit;
            width: 36px;
            height: 36px;
          }
        }

        &--right {
          svg {
            stroke: #e60000;
            width: 30px;
            height: 30px;
          }
        }

        &--bottom {
          display: none;
        }
      }
    }
  }
}

@keyframes slide-in {
  0% {
    top: -100vh;
  }
  100% {
    top: 0;
  }
}

@keyframes opacity-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 1055px) {
  .search {
    padding: 90px 0;
  }
}

@media only screen and (max-width: 1024px) {
  .search {
    padding: 52px 0;
  }
}

@media only screen and (max-width: 1000px) {
  .search {
    &__container {
      max-width: 100%;
      padding-top: 60px;
    }

    &__form {
      padding: 40px 0 28vh;
    }
  }
}

@media only screen and (max-width: 639px) {
  .search {
    padding: 48px 0;
  }
}
