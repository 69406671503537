.phone-selector {
    &--background {
        margin-bottom: 20px;
        padding: 40px 0 30px;

        &--abbey {
            background-color: $color-abbey;
            color: $color-white;
        }

        &--blue-lagoon {
            background-color: $color-blue-lagoon;
            color: $color-white;
        }
    }

    &__options {
        position: relative;
        margin: 0 -30px;
    }

    &__option {
        padding: 0 30px;

        &--disabled {
            opacity: 0.5;
        }

        &--input#{&}--disabled {
            position: relative;

            // Need a pseudo element because Firefox won't dispatch events
            // on disabled inputs ancestors when triggered on the input itself.
            // @see: https://lists.w3.org/Archives/Public/public-html/2015Oct/0010.html
            &::before {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 1;
            }
        }
    }

    &__conjunction-divide {
        background-color: currentColor;
        bottom: 0;
        left: 50%;
        position: absolute;
        top: 0;
        transform: translate(-50%);
        width: 1px;
    }

    &__conjunction {
        @include position;
        text-transform: uppercase;
        width: auto;
    }

    &--background--abbey &__conjunction {
        background-color: $color-abbey;
    }

    &--background--blue-lagoon &__conjunction {
        background-color: $color-blue-lagoon;
    }

    &__input {
        background-color: transparent;
        border-color: currentColor;
        color: currentColor;

        &:focus,
        &:valid {
            background-color: $color-white;
            color: $color-mine-shaft;
        }

        &::placeholder {
            color: $color-mercury-dark;
        }

        &:focus::placeholder,
        &:valid::placeholder {
            color: $color-dusty-gray;
        }
    }
}

@media only screen and (max-width: $breakpoint-tablet) {
    .phone-selector {
        &--background {
            padding: 35px 0 25px;
        }
    }
}

@media only screen and (max-width: $breakpoint-tablet) {
    .phone-selector {
        &__conjunction-divide {
            display: none;
        }

        &__conjunction {
            margin: 10px 0;
            position: static;
            text-align: center;
            transform: none;
        }

        &--background &__conjunction {
            background-color: transparent;
        }

        &--background {
            padding: 25px 10px;
        }
    }
}
