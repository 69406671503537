.form {
  &__row {
    label {
      span {
        input + svg {
          fill: #e60000;
          color: #ffffff;
          width: 21px;

          &:hover {
            fill: #e60000;
            color: #ffffff;
          }
        }
      }
    }
  }
}

.alert-box {
  display: block;
  margin-top: -15px;
  margin-bottom: 40px;

  &__header {
    background-color: #E60000;
    padding: 5px;
    text-align: center;
    border-radius: 5px 5px 0 0;

    svg {
      fill: #ffffff;
      color: #E60000;
      width: 19px;

      &:hover {
        fill: #ffffff;
        color: #E60000;
      }
    }
  }

  &__content {
    border-radius: 0 0 5px 5px;
    border: 1px solid #E60000;
    padding: 20px;
  }
}
