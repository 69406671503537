.share {
    color: $color-dove-gray;
    position: relative;
    text-align: right;

    &__button {
        padding: 14px 0;
        position: relative;
        z-index: 1;
        display: inline-block;
    }

    &__links-wrapper {
        display: inline-block;
        overflow: hidden;
        position: absolute;
        right: 35px;
        height: 100%;
        top: 0;
    }

    &__social-links {
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        left: 100%;
        top: 0;
        z-index: 0;
        transition: transform 0.3s ease-in;

        &--show {
            transform: translateX(-100%);
        }
    }

    &--wild-sand &__social-links {
        background: $color-wild-sand;
    }

    &__item {
        display: table-cell;
        vertical-align: bottom;
    }

    &__link {
        display: inline-block;
        padding: 10px;
    }
}

@media only screen and (max-width: $breakpoint-tablet) {
    .share {
        &__spring {
            padding: 0;
        }

        &__links-wrapper {
            left: 0;
        }
    }
}
