@import "../../../ws10/resources/assets/styles/settings/_colors";
@import "../../../ws10/resources/assets/styles/settings/_font-sizes";

.form {
  &--input {
    position: relative;
    display: block;
  }
  
  &__label {
    font-size: $base-font-size-desktop;
  }

  &__input__icon {
    position: absolute;
    right: 10px;
    top: 11px;
    width: 30px;
    height: 30px;
    color: #666;
  }

  &__toggle {
    display: flex;
    flex-direction: row;
  }

  &__toggle-button {
    font-size: 20px;
    padding: 11px 37px;
    text-align: center;

    &:hover {
      cursor: pointer;
    }

    &--grey {
      border: 1px solid $color-dove-gray;

      &.form__toggle-button--dark {
        border: 1px solid $color-silver-chalice;

        &.form__toggle-button--checked {
          background-color: $color-silver-chalice;
          color: $color-mine-shaft;

          &:hover {
            background-color: $color-silver;
          }
        }
      }

      &.form__toggle-button--checked {
        background-color: $color-dove-gray;
        color: $color-white;

        &:hover {
          background-color: $color-mine-shaft;
        }
      }
    }

    &--green {
      border: 1px solid $color-green;

      &.form__toggle-button--checked {
        color: $color-white;
        background-color: $color-green;

        &:hover {
          background-color: darken($color-green, 10%);
        }
      }
    }

    &--red {
      border: 1px solid $color-red;

      &.form__toggle-button--dark {
        border: 1px solid $color-guardsman-red;

        &.form__toggle-button--checked {
          background-color: $color-guardsman-red;
        }
      }

      &.form__toggle-button--checked {
        color: $color-white;
        background-color: $color-red;

        &:hover {
          background-color: $color-red-berry;
        }
      }
    }
  }
}

.text-area {
  height: auto;
}

.text-area-cols {
  width: auto;
}
