.background {
  &__image {
    right: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-repeat: no-repeat;
  }
}

.banner {
  background-color: white;

  &__background {
    position: relative;
    overflow: hidden;

    &__image {
      background-size: cover;
      background-position: center;
      width: 55%;
    }
  }

  &--half-image {
    padding-top: 98px;
    .banner {
      &__wrapper {
        position: relative;
        height: 550px;
        overflow: hidden;
        color: black;
        background-color: #fff;
      }

      &__content {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 100%;
        padding: 0 32px 0 16px;

        .heading {
          margin-bottom: 35px;
        }
      }
    }
  }
}

@media only screen and (max-width: 968px) {
  .banner {
    &--half-image {
      padding-top: 52px;
    }
  }
}

@media only screen and (max-width: 639px) {
  .banner {
    overflow: hidden;

    &__background {
      &__image {
        background-size: cover;
        bottom: auto;
        height: 240px;
        width: 100%;
      }
    }

    &--half-image {
      padding-top: 49px;

      .banner {
        &__background {
          padding-top: 240px;
        }

        &__wrapper {
          height: auto;
        }

        &__content {
          padding: 16px;
          position: static;
          left: unset;
          top: unset;
          transform: none;
          text-align: center;

          .heading {
            margin-bottom: 24px;
            font-size: 20px;
          }

          .button {
            margin-bottom: 0;
            width: auto;
            margin: auto;
            padding: 10px 35px;
          }
        }
      }
    }

    &__spring {
      padding: 0;
    }
  }
}
