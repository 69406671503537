.cards {
  // padding: 50px 0; TODO remove comment

  &__wrapper {
    margin: 0 -10px;
    width: calc(100% + 20px);
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;

    &--center {
      justify-content: center;
    }

    &--3 {
      .card__item {
        width: calc((100% / 3) - 20px);

        &--column {
          .card {
            &__image {
              height: 320px;
            }

            // &__content {
            //   height: calc(100% - 320px);
            // }
          }
        }
      }

      .cards {
        &__rows,
        &__wrap {
          width: calc((100% / 3) * 2);
        }
      }
    }

    &--2 {
      .card__item {
        width: calc((100% / 2) - 20px);

        &--column {
          .card {
            &__image {
              height: 350px;
            }

            // &__content {
            //   height: calc(100% - 350px);
            // }
          }
        }
      }

      .cards {
        &__rows,
        &__wrap {
          width: 50%;
        }
      }
    }
  }

  &__rows {
    display: flex;
    flex-direction: column;

    .card__item {
      width: calc(100% - 20px);
      min-height: 250px;

      &--dashboard {
        min-height: auto;

        .card {
          &__content {
            width: 100%;
          }

          &--bottom {
            white-space: nowrap;
          }
        }
      }
    }
  }

  &__wrap {
    &.cards__grid {
      &--3 {
        .card__item {
          width: calc((100% / 3) - 20px);
        }
      }

      &--2 {
        .card__item {
          width: calc((100% / 2) - 20px);
        }
      }
    }
  }
}

@media only screen and (max-width: 968px) {
  .cards {
    &__grid {
      &--3 {
        .card__item {
          width: calc((100% / 2) - 20px);

          &--column {
            .card {
              &__image {
                height: 280px;
              }

              // &__content {
              //   height: calc(100% - 280px);
              // }
            }
          }
        }

        .cards {
          &__rows,
          &__wrap {
            width: 50%;
          }
        }
      }

      &--2 {
        .card__item {
          &--column {
            .card {
              &__image {
                height: 250px;
              }

              // &__content {
              //   height: calc(100% - 250px);
              // }
            }
          }
        }
      }
    }

    &__rows {
      .card__item {
        width: calc(100% - 20px);
      }
    }

    &__wrapper {
      &.has--scroll {
        overflow-x: auto;
        overflow-y: hidden;
        flex-wrap: nowrap;

        &.cards__grid--2 {
          .card__item {
            min-width: 450px;
          }

          .cards {
            &__rows {
              min-width: calc(450px + 20px);
            }
          }
        }

        &.cards__grid--3 {
          .card__item {
            width: 350px;
            flex-shrink: 0;
          }

          .cards__grid--2 {
            width: calc(370px * 2);
            flex-shrink: 0;

            .card__item {
              flex-shrink: 0;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 639px) {
  .cards {
    &__grid {
      &--3,
      &--2 {
        .card__item {
          width: calc(100% - 20px);

          &--column {
            .card {
              &__image {
                height: 190px;
              }

              // &__content {
              //   height: calc(100% - 190px);
              // }
            }
          }
        }

        .cards {
          &__rows,
          &__wrap {
            width: 100%;
          }
        }
      }
    }

    &__wrap {
      &.cards__grid {
        &--3,
        &--2 {
          .card__item {
            width: calc(100% - 20px);
          }
        }
      }
    }

    &__rows {
      .card__item {
        min-height: auto;
      }
    }

    &__wrapper {
      &.has--scroll {
        &.no-scroll--mobile {
          overflow-x: hidden;
          flex-wrap: wrap;

          &.cards {
            &__grid--2 {
              .card__item,
              .cards__rows {
                min-width: calc(100% - 20px);
              }
            }
          }
        }

        &.cards__grid--3 {
          .card__item {
            width: 320px;
          }

          .cards__grid--2 {
            width: calc(340px * 2);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .cards {
    &__wrapper {
      &.has--scroll {
        &.cards__grid--3 {
          .card__item {
            width: 280px;
          }

          .cards__grid--2 {
            width: calc(300px * 2);
          }
        }
      }
    }
  }
}
