* {
  box-sizing: border-box;

  &:focus {
    outline: 0;
  }

  &:active {
    outline: 0;
  }
}
