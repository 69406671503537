$pass-base-font-size: 28px;

.pass {
    display: inline-block;
    height: to-em(241px, $pass-base-font-size);
    position: relative;
    text-align: left;
    transform: perspective(to-em(58px, $pass-base-font-size)) rotateY(3deg);
    width: to-em(128px, $pass-base-font-size);
    z-index: 0;
    margin: to-em(30px, $pass-base-font-size) 0;

    &::after {
        background: linear-gradient(
            45deg,
            hex-to-rgba($color-black, 0.25) 0%,
            transparent 50%,
            transparent 100%
        );
        border-radius: 0 0 to-em(7px, $pass-base-font-size)
            to-em(7px, $pass-base-font-size);
        bottom: 0;
        content: "";
        height: 90%;
        left: 0;
        position: absolute;
        right: 0;
        z-index: 1;
    }

    &::before {
        content: "";
    }

    &--flat::after {
        display: none;
    }

    &--single {
        vertical-align: middle;

        &--small {
            margin: 0;
        }
    }

    &--green {
        color: $color-pistachio;
    }

    &--blue {
        color: $color-cerulean;
    }

    &--orange {
        color: $color-tangerine;
    }

    &--purple {
        color: $color-seance;
    }

    &--disabled {
        color: $color-silver-chalice;
    }

    &--group {
        margin-left: to-em(-22px, $pass-base-font-size);
        margin-right: to-em(-22px, $pass-base-font-size);
    }

    &--horizontal {
        margin-left: to-em(57px, $pass-base-font-size);
        transform: rotate(90deg);
    }

    &--image {
        font-size: 20px;
        transform: translate(-50%, -50%) rotate(90deg);
        top: 50%;
        position: absolute;
        left: 50%;
        margin: 0;
    }

    &--flip {
        -ms-transform: rotateY(-10deg);
        transform: perspective(to-em(58px, $pass-base-font-size)) rotateY(-3deg);
        z-index: 5;
        direction: ltr;
        margin: 0 to-em(-15px, $pass-base-font-size);
    }

    &--flip &__icon {
        margin-left: to-em(5px, $pass-base-font-size);
        margin-top: to-em(55px, $pass-base-font-size);
        text-align: center;
        transform: rotate(0);
    }

    &--flip &__label {
        margin-top: to-em(-60px, $pass-base-font-size);
    }

    &__label {
        color: $color-red;
        font-size: 1em;
        height: 50%;
        line-height: 1;
        margin-top: to-em(-28px, $pass-base-font-size);
        padding: to-em(11px, $pass-base-font-size)
            to-em(16px, $pass-base-font-size);
        text-transform: uppercase;
        transform: rotate(-90deg);
        width: 100%;
    }

    &__label-bold {
        font-family: $font-family-vodafone-regular-bold;
    }

    &__icon {
        height: 50%;
        line-height: to-em(110px, $pass-base-font-size);
        margin-top: to-em(23px, $pass-base-font-size);
        transform: rotate(-90deg);
        width: 100%;
    }

    &__icon-svg {
        height: to-em(62px, $pass-base-font-size);
        overflow: visible !important; // override normalize.css fix for IE
        stroke-width: 9px;
        width: to-em(62px, $pass-base-font-size);
    }

    &__logo-svg {
        color: transparent;
        height: to-em(32px, $pass-base-font-size);
        margin-top: to-em(9px, $pass-base-font-size);
        overflow: visible !important; // override normalize.css fix for IE
        width: to-em(32px, $pass-base-font-size);
    }

    &__background {
        background-image: url("../../images/group/images-non-responsive/vodafone-pass.svg");
        background-repeat: no-repeat;
        background-size: cover;
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        text-align: left;
        top: 0;
        z-index: -2;
    }

    .no-csstransitions &__background {
        background-image: url("../../images/group/images-non-responsive/vodafone-pass.png");
    }

    &--disabled &__background {
        background-image: url("../../images/group/images-non-responsive/vodafone-pass-disabled.svg");
    }

    &__shadow {
        position: absolute;
        top: to-em(12px, $pass-base-font-size);
        left: to-em(6px, $pass-base-font-size);
        right: to-em(6px, $pass-base-font-size);
        bottom: to-em(6px, $pass-base-font-size);
        z-index: -3;
        box-shadow: 0 to-em(2px, $pass-base-font-size)
            to-em(26px, $pass-base-font-size) hex-to-rgba($color-black, 0.9);
        border-radius: to-em(4px, $pass-base-font-size);
    }
}
