.tooltip {
    display: inline-block;
    height: 0;
    position: relative;
    font-size: 18px;
    font-family: $font-family-vodafone-regular;

    &--onload {
        position: absolute;
    }

    &__trigger {
        position: relative;
    }

    &--active &__trigger::before {
        content: "";
        border: 12px solid transparent;
        border-top-color: $color-wild-sand;
        position: absolute;
        filter: drop-shadow(0 2px 1px $color-silver-chalice);
        z-index: 9999;
        margin-left: 50%;
        top: -15px;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    &__content--onload::after {
        left: 50%;
        margin-left: -6px;
        content: "";
        border: 12px solid transparent;
        border-top-color: $color-wild-sand;
        position: absolute;
        bottom: -24px;
        filter: drop-shadow(0 2px 1px $color-silver-chalice);
        z-index: 9999;
    }

    &__content {
        background-color: $color-wild-sand;
        color: $color-black;
        border-radius: 10px;
        padding: 40px 20px 20px;
        width: 325px;
        filter: drop-shadow(0 1px 2px $color-silver-chalice);
        position: absolute;
        bottom: 15px;
        left: -20px;
        display: none;
        z-index: 9998;

        &--onload {
            left: auto;
            margin-left: -172px;
        }
    }

    &--active &__content {
        display: block;
    }

    &__content--left {
        right: -20px;
        left: auto;
    }

    &__close {
        right: 15px;
        position: absolute;
        top: 10px;
        background-color: transparent;
        border: none;
    }
}

@media only screen and (max-width: $breakpoint-tablet) {
    .tooltip {
        position: static;
        font-size: 16px;

        &--onload {
            left: 0;
            width: 100%;
            position: absolute;

            .tooltip__content {
                bottom: 15px;
            }
        }

        &__content {
            width: 100%;
            bottom: unset;
            position: absolute;
            left: 0;

            &--onload {
                margin-left: 0;
            }
        }

        &--active &__trigger::before {
            margin-left: 8px;
            border: 8px solid transparent;
            border-top-color: $color-wild-sand;
            transform: translateX(-100%);
            // margin-left: 0;
            top: -10px;
            bottom: unset;
            left: 50%;

            &--info::before {
                margin-left: -16px;
            }
        }
    }
}
