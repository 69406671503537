.actions {
    &--shop {
        position: absolute;
    }

    &--tabs {
        position: absolute;
    }

    &__item {
        display: inline-block;
    }

    &__filter,
    &__reset {
        vertical-align: middle;
    }

    &__filter {
        appearance: none;
        background: $color-white;
        cursor: pointer;
        display: inline-block;
        margin-right: 15px;
        padding: 10px 20px;
        text-align: center;

        border: 1px solid $color-silver;
        color: $color-mine-shaft;

        &--active {
            background-color: $color-white;
            border-color: $color-red;
            color: $color-red;
        }
    }

    &--lagoon &__filter {
        background: $color-blue-lagoon;
        color: $color-white;
        border-color: $color-white;

        &--active {
            background: $color-white;
            color: $color-blue-lagoon;
        }
    }

    &__icon {
        &--chevron {
            width: 20px;
            height: 20px;
            margin-left: 35px;
        }
    }

    &__filter--active &__icon--chevron {
        transform: scaleY(-1);
    }

    &__reset {
        appearance: none;
        background-color: transparent;
        border: none;
        padding: 0;
        color: $color-silver;

        &--inactive {
            color: $color-silver;
        }

        &--active {
            color: $color-mine-shaft;
            text-decoration: underline;

            &--lagoon {
                color: $color-white;
            }
        }
    }

    &__submit {
        background-color: $color-dove-gray;

        &--active {
            background-color: $color-red;
        }
    }

    &__sort {
        box-shadow: none;
    }

    &__sort-by {
        display: inline-block;
        width: 310px;
        float: right;
    }

    &__border {
        border: 1px solid $color-silver;
    }
}

@media only screen and (max-width: $breakpoint-desktop) {
    .actions {
        &--shop {
            position: static;
            padding-bottom: 30px;
        }

        &--tabs {
            float: left;
            position: static;
        }
    }
}

@media only screen and (max-width: $breakpoint-tablet) {
    .actions {
        display: block;

        &--tabs {
            float: none;
        }

        &__grid {
            display: block;
            height: 100%;
            letter-spacing: -0.215em;
        }

        &__item {
            display: inline-block;
            text-align: right;
            letter-spacing: normal;
            vertical-align: middle;
        }

        &--sort {
            border-top: 1px solid $color-alto;
            margin: 0 -10px;
            padding: 15px 0;

            &--long {
                max-width: none;
            }
        }

        &--sort &__sort {
            display: inline-block;
        }

        &__sort-by {
            width: 280px;
        }

        &__filter {
            display: inline-block;
            margin-bottom: 0;
            margin-right: 20px;
            width: auto;
        }

        &__reset {
            margin-top: 15px;
            vertical-align: text-bottom;
            float: left;
        }
    }
}
