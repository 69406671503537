table {
    width: 100%;
}

caption {
    text-align: left;
}

th,
td {
    font-weight: $font-weight-normal;
    text-align: left;
    vertical-align: top;
}
