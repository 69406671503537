.edit-mode {
  &.dashboard {

    .card {
      position: relative;
    }

    .toggle-switch {
      position: absolute;
      top: -37px;
      left: 0;

      &__title {
        position: absolute;
        left: 63px;
        font-family: "VodafoneRegular";
        color: #333333;
        outline: none;

        strong {
          font-family: "VodafoneRegular" !important;
        }
      }
    }

    .dashboard {
      &__header {
        &--person {
          opacity: 0.35;
        }
        &--actions {
          button {
            margin-left: 25px;

            &:first-child {
              margin-left: 0;
            }
          }
        }
      }

      &__content {
        > .grid__item {
          padding-top: 50px;

          &--3\/5 {
            .grid__item:nth-child(2) {
              padding-top: 50px;
            }            
          }
        }

        margin: 30px -15px 15px;

        .card {
          &--hide {
            .card {
              &__content {
                opacity: 0.35;
              }
            }
          }
        }

        .grid {       
          &__item {
            &--1\/1,
            &--1\/3,
            &--3\/5,
            &--2\/5 {

              .card {
                &:after {
                  content: "";
                  display: block;
                  height: calc(100% + 69px);
                  border: 1px dashed #8D8D8D;
                  position: absolute;
                  right: -15px;
                  top: -50px;
                }

                &:before {
                  content: "";
                  display: block;
                  width: calc(100% + 30px);
                  border: 1px dashed #8D8D8D;
                  position: absolute;
                  left: -15px;
                  top: -50px;
                }
              }
            }

            &--1\/1 {
              .card {
                &:after {
                  display: none;
                }

                &:before {
                  width: 100%;
                  left: 0;                    
                }
              }
            }

            &--1\/3 {
              &:nth-child(3) {
                .card {
                  &:after {
                    display: none;
                  }

                  &:before {
                    width: calc(100% + 15px);                  
                  }
                }
              }

              &:nth-child(1) {
                .card {
                  &:before {
                    width: calc(100% + 15px);
                    left: 0;                    
                  }
                }
              }
            }

            &--3\/5 {
              .card {
                &:before {
                  width: calc(100% + 15px);
                  left: -15px;  
                }
              }
            }

            &--2\/5 {
              .card {
                &:before {
                  width: calc(100% + 15px);
                  left: 0;
                }

                &:after {
                  height: calc(100% + 102px);
                }
              }
            }
          }
        }
      }
    }
    
    &.dashboard__dark--mode {
      .progress {
        background: rgba(235, 235, 235, 0.15);

        &--primary {
          .progress__bar {
            background: #ffffff;
          }
        }
      }

      .toggle-switch {
        &__title {
          color: #FFFFFF;
        }
      }
    }
  }
}


@media only screen and (max-width: 1024px) {
  .edit-mode {
    &.dashboard {
      .dashboard {
        &__content {
          .grid {
            &__item {
              &--1\/3 {
                &:nth-child(2) {
                  .card {
                    &:after {
                      display: none;
                    }

                    &:before {
                      width: calc(100% + 15px);                  
                    }
                  }
                }
                
                &:nth-child(3) {
                  .card {
                    &:after {
                      display: block;
                    }

                    &:before {
                      width: calc(100% + 15px); 
                      left: 0;                 
                    }
                  }
                }
              }

              &--2\/5 {
                .card {
                  &:before {
                    left: -15px;
                  }

                  &:after {
                    display: none;
                  }
                }
              }

              &--3\/5 {
                .grid__item {
                  &:first-child {
                    .card {
                      &:before {
                        left: 0;
                      }

                      &:after {
                        display: block;
                      }
                    }
                  }

                  &:nth-child(2) {
                    padding-top: 15px;
                  }
                }                
              }
            }
          }
        }
      }
    }
  }
}


@media only screen and (max-width: 639px) {
  .edit-mode {
    &.dashboard {
      .dashboard {
        &__header {
          &--actions {
            text-align: left;

            button {
              width: 100%;
              display: block;

              &:first-child {
                margin-bottom: 15px;
              }

              &:last-child {
                margin-left: 0;
              }
            }
          }
        }

        &__content {
          .grid {
            &__item {
              &--1\/1,
              &--1\/3,
              &--3\/5,
              &--2\/5 {
                .card {
                  &:after {
                    display: none;
                  }

                  &:before {
                    width: 100% !important;
                    left: 0;
                  }
                }
              }

              &--3\/5 {
                .grid__item {
                  &:nth-child(2) {
                    padding-top: 50px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

