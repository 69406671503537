.icon {
  display: inline-block;
  fill: none;
  stroke: currentColor;
  stroke-width: 7px;
  vertical-align: middle;

  &--thin {
    stroke-width: 5px;
  }

  &--extra-small {
    width: $icon-extra-small-size;
    height: $icon-extra-small-size;
  }

  &--small {
    width: $icon-small-size;
    height: $icon-small-size;
  }

  &--medium {
    width: $icon-medium-size;
    height: $icon-medium-size;
  }

  &--large {
    width: $icon-large-size;
    height: $icon-large-size;
  }

  &--extra-large {
    width: $icon-extra-large-size;
    height: $icon-extra-large-size;
  }

  &:hover {
    fill: none;
    stroke: currentColor;
  }

  &--fill,
  &--fill:hover {
    stroke: none;
    stroke-width: 0;
    fill: currentColor;
  }

  &--captioned {
    vertical-align: text-top;
  }

  &--pad {
    padding: 5px;
  }

  &--empty,
  &--empty:hover {
    stroke: none;
    stroke-width: 0;
    fill: none;
  }
}

@media only screen and (max-width: $breakpoint-desktop) {
  .icon {
    &--extra-small {
      width: 18px;
      height: 18px;
    }

    &--small {
      width: 24px;
      height: 24px;

      &.accordion__chevron {
        width: 32px !important;
        height: 32px !important;
        font-size: 0;
        margin-right: 10px;
        margin-left: 0;
        fill: currentColor !important;
      }

      &.navigation__user--close {
        width: 28px;
        height: 28px;
        stroke: $color-white;
        fill: $color-white;
        stroke-width: 0;
      }
    }

    &--medium {
      width: 36px;
      height: 36px;
    }

    &--large {
      width: 44px;
      height: 44px;
    }

    &--extra-large {
      width: 52px;
      height: 52px;
    }
  }
}

@media only screen and (max-width: $breakpoint-tablet) {
  .icon {
    &--extra-small {
      width: 16px;
      height: 16px;
    }

    &--small {
      width: 20px;
      height: 20px;

      &.accordion__chevron {
        width: 26px !important;
        height: 26px !important;
      }
    }

    &--medium {
      width: 28px;
      height: 28px;
    }

    &--large {
      width: 36px;
      height: 36px;
    }

    &--extra-large {
      width: 52px;
      height: 52px;
    }
  }
}
