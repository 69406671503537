.suggest {
    position: relative;

    &__input {
        &[type="search"] {
            appearance: none;
            box-sizing: border-box;
            padding-right: 20px;
        }
    }

    &__suggestions {
        background-color: $color-white;
        box-shadow: 0 2px 4px hex-to-rgba($color-black, 0.2);
        color: $color-mine-shaft;
        left: 0;
        max-height: 250px;
        overflow: auto;
        position: absolute;
        right: 0;
        top: 100%;
        z-index: 100;
    }

    &__suggestion {
        -webkit-appearance: none;
        appearance: none;
        background-color: $color-white;
        border: none;
        display: block;
        padding: 15px 20px;
        text-align: left;
        width: 100%;

        &:hover,
        &:focus {
            background-color: hex-to-rgba($color-red, 0.05);
            color: $color-red;
            cursor: pointer;
            outline: none;
        }

        &--no-results {
            &,
            &:hover,
            &:focus {
                background-color: transparent;
                color: $color-dusty-gray;
                font-style: italic;
            }
        }
    }

    &__icon {
        display: inline-block;
        height: 30px;
        margin-right: 10px;
        vertical-align: middle;
        width: 30px;
    }
}

@media only screen and (max-width: $breakpoint-tablet) {
    .suggest {
        &__suggestions {
            position: static;
        }
    }
}
