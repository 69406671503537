.footer {
  background-color: black;
  color: white;
  padding: 24px 10px;

  .spring {
    padding: 0 10px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #666666;
    padding-bottom: 15px;
  }

  .social {
    margin: 0;

    &__title {
      color: white;
      font-family: "VodafoneRegularBold";
      font-size: 20px;
      line-height: 1.4;
    }

    ul {
      margin: 16px 0;
    }

    &__item {
      margin: 0 8px;
      padding: 0;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  li {
    list-style: none;
  }

  &__brand {
    svg {
      width: 48px;
      height: 48px;
    }
  }

  &__content {
    padding: 28px 0;
  }

  &__menu {
    .heading {
      color: white;
      font-family: "VodafoneRegularBold";
      font-size: 20px;
      margin: 0;
    }

    .grid__item {
      input {
        display: none;
      }
    }

    &--list {
      padding: 20px 20px 0 0;
    }

    &--item {
      padding: 3px 0;

      a {
        font-size: 18px;
      }
    }

    &--icon {
      display: none;
    }
  }

  &__bottom {
    padding: 28px 0;

    .list {
      display: flex;
      justify-content: center;
    }

    li {
      padding: 0 12px;
      border-left: 1px solid #666666;

      &:first-child {
        border-left: 0px;
      }
    }
  }
}

// Tablet
@media only screen and (max-width: 968px) {
  .footer {
    .grid__item {
      width: 100%;
      padding: 20px 0;
      border-bottom: 1px solid #666666;
      position: relative;

      input {
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        display: block;
        width: 100%;
        height: 70px;
        opacity: 0;

        & ~ .footer__menu--list {
          visibility: hidden;
          max-height: 0;
          height: 0;
          padding: 0;
          overflow: hidden;
        }

        &:checked + .footer__menu--header {
          .footer__menu--icon .icon {
            transform: rotate(180deg);
          }
        }

        &:checked ~ .footer__menu--list {
          visibility: visible;
          max-height: 100vh;
          height: auto;
          padding: 20px 0 0 0;
        }
      }
    }

    &__content {
      padding: 0 0 28px 0;
    }

    &__menu {
      &--header {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &--icon {
        display: inline-block;

        svg {
          stroke: white;
        }
      }
    }

    &__bottom {
      padding: 0 0 4px 0;
    }
  }
}

// Mobile
@media only screen and (max-width: 639px) {
  .footer {
    .social {
      text-align: left;

      &__item {
        margin: 0 6px;
      }

      .icon {
        width: 24px;
        height: 24px;
      }
    }

    &__menu {
      .heading {
        font-size: 18px;
        font-family: "VodafoneRegular";
      }

      &--item {
        a {
          font-size: 16px;
        }
      }
    }

    &__content {
      padding: 0 0 24px 0;
    }

    &__bottom {
      margin: 0;

      .list {
        flex-wrap: wrap;

        a {
          font-size: 16px;
        }
      }

      li {
        margin: 8px 0;
        padding: 0;
        line-height: 20px;

        a {
          font-size: 16px;
          padding: 0 8px;
        }
      }
    }
  }
}
