.broadband{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    &--title{
        margin-top: 32px;
        display: flex;
        justify-content: center;
    }
    &--form{
    display:flex;
    flex-direction: row;
    align-items: center;
    .form{
        margin: 0 18px;
        max-width:  360px;
        width: 100%;
    }
    }
    .btn {
        // width: 100%;
        width: 320px;
        height: 50px;
        position: relative;
        top: 12px;
    }

}
.plans{
   margin : 16px 0px
}
@media only screen and (max-width: 640px) {
   .broadband{
    &--form {
        flex-direction:  column;
        
    }
    .btn{
        width: 100%;
    }
   }
  }
  