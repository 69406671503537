.roaming {
    &__form {
        position: relative;
        margin-bottom: 50px;
        margin-top: 20px;
    }

    &__heading {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
    }

    &__field {
        background-color: $color-mine-shaft-dark;
        border-radius: 3px;
        display: block;
        padding: 5px;
        position: relative;
    }

    &__reset {
        top: 5px;
        right: 5px;
    }

    &__submit {
        border: 5px solid $color-mine-shaft-dark;
        border-radius: 3px;
        bottom: 0;
        position: absolute;
        right: 0;
        top: 0;
    }

    &__icon {
        height: 25px;
        width: 25px;

        &--rates {
            height: 35px;
            margin-right: 15px;
            width: 35px;
        }
    }

    &__information {
        color: $color-white;
    }

    &__four-g {
        width: 100%;
        text-align: center;
        margin: 5px 0 40px;
        font-size: 22px;

        &--hidden {
            display: none;
        }
    }

    &__unavailable {
        text-align: center;
    }

    &__suggestions {
        background-color: $color-white;
        color: $color-mine-shaft;
        display: none;
        max-height: 200px;
        overflow: auto;
        position: absolute;
        left: 0;
        right: 50px;
        z-index: 2;
    }

    &__suggestion {
        -webkit-appearance: none;
        appearance: none;
        background-color: transparent;
        border: none;
        padding: 12px 20px;
        width: 100%;
        text-align: left;

        &:focus {
            box-shadow: none;
        }
    }

    &__rates {
        background-color: inherit;
        border-collapse: collapse;
        border-spacing: 0;
        color: $color-white;
        margin: 25px 0 22px;
    }

    &__row {
        border-bottom: 1px solid $color-white;

        &--first {
            border-top: 1px solid $color-white;
        }
    }

    &__rate {
        font-family: $font-family-vodafone-regular-bold;
        text-align: right;
        padding: 12px 10px 12px 0;
    }

    &__rate-heading {
        padding: 12px 0 12px 10px;
        vertical-align: middle;
    }

    &__content {
        display: none;
    }

    &__not-found {
        text-align: center;
    }

    &__filters {
        visibility: hidden;
        height: 0;

        &--visible {
            visibility: visible;
            height: auto;
        }
    }

    &__advert {
        margin-top: 60px;
    }

    &__country {
        display: block;
    }
}

@media only screen and (max-width: $breakpoint-desktop) {
    .roaming {
        &__form {
            margin-bottom: 40px;
        }
    }
}

@media only screen and (max-width: $breakpoint-tablet) {
    .roaming {
        &__form {
            margin-bottom: 30px;
            margin-top: 10px;
        }

        &__country {
            display: inline-block;
        }

        &__rates {
            margin-top: 20px;
        }

        &__four-g {
            margin-bottom: 30px;
            margin-top: 10px;
            font-size: 20px;
        }

        &__advert {
            margin-top: 40px;
        }
    }
}
