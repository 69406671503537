.card {
  border: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  width: 100%;
  &.clickable:hover {
    cursor: pointer;
  }
  &__submenuBanner {
    color: #fff;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; 
    border-radius: 0px 6px 6px 0px;
  }
  &__color {
    &--white {
      background-color: #ffffff;
      color: #333333;
    }

    &--grey {
      background-color: #333333;
      color: #ffffff;
    }

    &--red {
      background: #a90000;
      background: linear-gradient(90deg, #a90000 0%, #e60000 45%, #ff0000 75%);
      color: #ffffff;
    }

    &--img {
      background-image: url(../../../assets/images/image@3x.png);
      background-size: cover;
      color: white;
    }
  }

  &__align {
    &--left {
      text-align: left;
    }

    &--center {
      text-align: center;
    }

    &--right {
      text-align: right;
    }
  }

  &__content {
    font-family: "VodafoneRegular";
    border-radius: 6px;
    padding: 16px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    .banner-text {
      max-width: 120px;
    font-size: 0.9rem;
    }
    h3 {
      font-size: 18px;
      font-family: "VodafoneRegularBold";
      position: relative;
      color: inherit;
      display: flex;
      align-items: center;

      &.no-icon {
        padding-left: 0;
      }

      .icon {
        margin-right: 8px;
      }
    }
  }

  &__info {
    margin-top: 35px;

    h5 {
      font-size: 18px;
      font-family: "VodafoneRegular";
      line-height: 1;

      span {
        font-family: "VodafoneLight";
        font-size: 56px;
        padding-right: 5px;
      }

      .unit {
        font-size: 40px;
      }
    }

    p {
      margin-bottom: 0;
      font-size: 14px;
      color: #999999;
    }

    &--bill {
      margin-top: 15px;
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }

    &.top--up {
      margin-top: 8px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      span {
        font-size: 14px;
        color: #666666;
      }
    }
  }

  &--amount {
    font-family: "VodafoneLight";
    text-align: left;
    font-size: 56px;
    line-height: 1;

    span {
      font-size: 40px;

      p {
        margin: 5px 0 0;
      }
    }

    &-small {
      font-size: 28px;
    }
  }

  .progress {
    margin: 15px 0;
    border-radius: 3px;
    background: #ebebeb;
    box-shadow: none;
  }
}
