.button {
  display: inline-block;
  border: 1px solid transparent;
  font-size: 20px;
  padding: 8px 20px;
  border-radius: 6px;
  text-align: center;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;

  &--primary {
    color: $color-white;
    background-color: $color-red;

    &:hover {
      background-color: $color-red-berry;
    }
  }
}

@media only screen and (max-width: 639px) {
  .button {
    display: block;
    font-size: 18px;
    padding: 8px 16px;
    width: 100%;
  }
}
