.bundle-data {
    $color-cinderella: #fdd4d4;
    $color-cavern-pink: #e5bfbf;

    border-left-width: 0;
    border-right-width: 0;
    display: block;
    letter-spacing: -0.215em;
    height: 110px;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;

    &:after {
        content: "";
        display: inline-block;
        vertical-align: bottom;
        height: 100%;
    }

    &--narrow {
        border-left-width: 1px;
        border-right-width: 1px;
        height: auto;
    }

    &--auto {
        height: auto;
    }

    &__value {
        background-color: $color-silver;
        border: 1px solid $color-silver;
        border-right-color: transparent;
        color: $color-mine-shaft;
        padding: 13px 0;
        display: inline-block;
        letter-spacing: normal;
        width: 50%;
        vertical-align: bottom;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &--inverse {
            background-color: transparent;
        }

        &--full {
            width: 100%;
        }
    }

    &--large &__value {
        height: 100%;
    }

    &--red &__value {
        background-color: $color-red;
        border-color: $color-red;
        border-right-color: transparent;
        border-left-color: transparent;
        color: $color-white;

        &--inverse {
            background-color: $color-white;
            color: $color-red;
        }
    }

    &--seance &__value {
        background-color: $color-seance;
        border-color: $color-seance;
        border-right-color: transparent;
        border-left-color: transparent;
        color: $color-white;

        &--inverse {
            background-color: $color-white;
            color: $color-seance;
        }
    }

    &--guardsman-red &__value {
        background-color: $color-guardsman-red;
        border-color: $color-guardsman-red;
        border-right-color: transparent;
        border-left-color: transparent;
        color: $color-white;

        &--inverse {
            background-color: $color-white;
            color: $color-guardsman-red;
        }
    }

    &--finn &__value {
        background-color: $color-finn;
        border-color: $color-finn;
        border-right-color: transparent;
        border-left-color: transparent;
        color: $color-white;

        &--inverse {
            background-color: $color-white;
            color: $color-finn;
        }
    }

    &--blue-lagoon &__value {
        background-color: $color-blue-lagoon;
        border-color: $color-blue-lagoon;
        border-right-color: transparent;
        border-left-color: transparent;
        color: $color-white;

        &--inverse {
            background-color: $color-white;
            color: $color-blue-lagoon;
        }
    }

    &--cinderella &__value {
        background-color: $color-cinderella;
        border-color: $color-cinderella;
        border-right-color: transparent;
        border-left-color: transparent;
        color: $color-guardsman-red;

        &--inverse {
            background-color: $color-white;
        }
    }

    &--cavern-pink &__value {
        background-color: $color-cavern-pink;
        border-color: $color-cavern-pink;
        border-right-color: transparent;
        border-left-color: transparent;
        color: $color-red-berry;

        &--inverse {
            background-color: $color-white;
        }
    }

    &--narrow &__value {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    &--narrow &__value--inverse {
        border-right-color: currentColor;
    }

    &--narrow &__heading {
        display: inline-block;
        margin-bottom: 0;
    }

    &__heading {
        display: block;
        margin: 3px 0 1px;
        font-family: VodafoneLight;
        letter-spacing: -0.215em;

        &--large-content {
            font-size: 24px;
        }
    }

    &__unit {
        font-size: 28px;
        line-height: 1em;
        letter-spacing: normal;
    }

    &__amount {
        font-size: 40px;
        line-height: 1em;
        letter-spacing: normal;
    }

    &--large &__amount {
        font-size: 56px;
    }

    &--small &__amount {
        font-size: 28px;
    }
}

@media only screen and (max-width: $breakpoint-desktop) {
    .bundle-data {
        height: 89px;

        &--auto {
            height: auto;
        }

        &__value {
            padding: 7px 0;
        }

        &--narrow {
            height: auto;
        }

        &__unit {
            font-size: 24px;
        }

        &__amount {
            font-size: 34px;
        }

        &--large &__amount {
            font-size: 40px;
        }

        &--small &__amount {
            font-size: 24px;
        }
    }
}

@media only screen and (max-width: $breakpoint-tablet) {
    .bundle-data {
        text-align: center;
        height: 78px;

        &--auto {
            height: auto;
        }

        &--narrow {
            height: auto;
        }

        &__unit {
            font-size: 20px;
        }

        &__amount {
            font-size: 24px;
        }

        &--large &__amount {
            font-size: 28px;
        }

        &--small &__amount {
            font-size: 20px;
        }

        &--narrow &__heading {
            display: block;
        }
    }
}
