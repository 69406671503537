.support-bubble {
    background-color: $color-white;
    box-shadow: 2px 3px 12px hex-to-rgba($color-dove-gray, 0.4);
    border-radius: 50%;
    color: $color-red;
    position: fixed;
    right: 40px;
    bottom: 40px;
    width: 80px;
    height: 80px;
    z-index: 9;
    transform: translate3d(0, 0, 0);

    &--static {
        position: static;
    }

    &::after {
        content: "";
        position: absolute;
        bottom: 16px;
        right: 21px;
        width: 22px;
        z-index: -1;
        transform: rotate(21deg);
        height: 2px;
        box-shadow: 24px 8px 8px hex-to-rgba($color-dove-gray, 0.4);
    }

    &__support {
        margin-top: -8px;
    }

    &__tail {
        color: $color-white;
        position: absolute;
        bottom: -18px;
        width: 30px;
        height: 18px;
        right: 0;
        transform: scaleX(-1);
    }

    &__wrapper {
        @include position;
        margin-top: 3px;
        text-align: center;
        font-size: 20px;
        line-height: 20px;
        font-family: $font-family-vodafone-regular-bold;
    }
}

@media only screen and (max-width: $breakpoint-desktop) {
    .support-bubble {
        right: 20px;
    }
}

@media only screen and (max-width: $breakpoint-tablet) {
    .support-bubble {
        right: 10px;

        &__wrapper {
            font-size: 18px;
            padding: 5px;
        }
    }
}
