.grid {
    @include clearfix;

    display: block;
    letter-spacing: -0.215em;
    list-style: none;
    margin: 0;
    padding-left: 0;

    &--auto {
        height: auto;
    }

    &--gutter {
        margin: 0 -10px;
    }

    &--gutter-vertical {
        margin-top: -10px;
        margin-bottom: -10px;
    }

    &__item {
        @include columns-classes;
        @include columns-classes($breakpoint: $breakpoint-desktop);
        @include columns-classes($breakpoint: $breakpoint-tablet);

        display: inline-block;
        letter-spacing: normal;
        margin: 0;
        vertical-align: top;

        &--gutter {
            padding: 0 10px;
        }

        &--gutter-vertical {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        &--align-left {
            text-align: left;
        }

        &--align-center {
            text-align: center;
        }

        &--align-right {
            text-align: right;
        }

        &--top {
            vertical-align: top;
        }

        &--middle {
            vertical-align: middle;
        }

        &--bottom {
            vertical-align: bottom;
        }

        &--pull {
            float: left;
        }

        &--push {
            float: right;
        }

        &--auto {
            height: auto;
        }

        &--bullet {
            position: relative;
            padding-left: 25px;

            &::before {
                content: "";
                width: 5px;
                height: 5px;
                left: 10px;
                background: $color-mine-shaft;
                border-radius: 50%;
                position: absolute;
                margin-top: 8px;
            }
        }
    }
}

@media only screen and (max-width: $breakpoint-desktop) {
    .grid {
        &__item {
            &--md-align-left {
                text-align: left;
            }

            &--md-align-center {
                text-align: center;
            }

            &--md-align-right {
                text-align: right;
            }

            &--md-top {
                vertical-align: top;
            }

            &--md-middle {
                vertical-align: middle;
            }

            &--md-bottom {
                vertical-align: bottom;
            }

            &--md-pull {
                float: left;
            }

            &--md-push {
                float: right;
            }
        }
    }
}

@media only screen and (max-width: $breakpoint-tablet) {
    .grid {
        &--gutter {
            margin: 0 -5px;
        }

        &--gutter-vertical {
            margin-top: -5px;
            margin-bottom: -5px;
        }

        &__item {
            &--gutter {
                padding: 0 5px 10px;
            }

            &--gutter-vertical {
                padding-top: 5px;
                padding-bottom: 5px;
            }

            &--sm-align-left {
                text-align: left;
            }

            &--sm-align-center {
                text-align: center;
            }

            &--sm-align-right {
                text-align: right;
            }

            &--sm-top {
                vertical-align: top;
            }

            &--sm-middle {
                vertical-align: middle;
            }

            &--sm-bottom {
                vertical-align: bottom;
            }

            &--sm-pull {
                float: left;
            }

            &--sm-push {
                float: right;
            }

            &--bullet {
                padding-left: 25px;
            }
        }
    }
}
