.form {
  &__input {
    background-color: white;
    border: 1px solid #999999;
    border-radius: 0;
    box-shadow: inset 1px 3px 4px 0 rgba(0, 0, 0, 0.1);
    color: #666666;
    display: block;
    position: relative;
    width: 100%;
    padding: 13px 20px 12px;
    font-size: 20px;
    height: 50px;

    &--shaded {
      box-shadow: inset 0 5px 9px -7px rgba(0, 0, 0, 0.4);
    }

    &::-ms-clear {
      display: none;
      height: 0;
      width: 0;
    }

    &--caption {
      margin-top: 14px;
    }

    &--selectable {
      background-color: transparent;
      overflow: hidden;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
      padding: 0;

      &--dark {
        color: #000000;
        border-color: #000000;
      }

      &::before {
        background: #cccccc;
        bottom: 8px;
        content: "";
        position: absolute;
        right: 46px;
        top: 8px;
        width: 1px;
        z-index: 1;
        display: none;
      }
    }

    &--select {
      display: inline-block;
    }

    &--range {
      background-color: transparent;
      border: none;
      padding: 0;
    }

    &--disabled {
      opacity: 0.5;
    }

    &--light {
      border: none;
    }

    &--round {
      border-radius: 25px;
    }
  }

  &__label {
    display: block;
    margin-bottom: 9px;

    &--checkable {
      display: inline-block;
      margin-bottom: 0;
      position: relative;
      vertical-align: middle;

      &--ruled::after {
        border-bottom: 1px solid currentColor;
        content: "";
        display: block;
        margin-top: -3px;
      }
    }

    &--required::after {
      content: " *";
      color: #e60000;
    }
  }

  &__row {
    display: block;
    margin-top: 28px;

    &--inline {
      display: inline-block;
    }

    &--slim {
      margin-top: 20px;
    }

    &--medium {
      margin-top: 40px;
    }

    &--large {
      margin-top: 60px;
    }

    &--leading {
      margin-top: 0;
    }
  }
}
