.navigation {
  &.navigation_ws10 {
    background-color: #222222;
    z-index: 11;

    .navigation {
      &__spring {
        display: flex;
        justify-content: space-between;
      }

      &__site {
        background-color: black;
        font-family: "VodafoneRegular";
        font-size: 14px;

        &:hover {
          color: black;
          background-color: white;
        }

        &--active {
          background-color: white;
          color: black;
        }
      }
    }
  }

  &.navigation--primary-ws10 {
    border-bottom: 0;
    box-shadow: 0 1px 3px rgba(black, 0.3);
    z-index: 10;

    .navigation {
      &__link {
        font-family: "VodafoneRegular";
        cursor: pointer;

        &--active {
          color: black;
          font-family: "VodafoneRegularBold";
        }

        &--main {
          padding: 24px 20px 12px;
          min-width: auto;
          font-size: 20px;
          margin: 0 5px;

          &:hover span:first-child {
            color: black;
          }
        }

        &:hover,
        &:focus {
          border: 0;
          outline: 0;
        }
      }

      &__logout {
        background: none;
        color: inherit;
        border: none;
      }

      &__item {
        &--user-icon {
          position: relative;

          a {
            padding: 21px 20px 20px;
          }
        }

        &--user-icon:hover {
          .navigation__menu-arrow {
            position: relative;
            overflow: visible;
          }

          .navigation--tertiary-user {
            margin-top: 1px;
            top: 103%;
            opacity: 1;
            visibility: visible;
          }

          .navigation__menu-arrow {
            &:before,
            &:after {
              display: block;
            }
          }
        }
      }

      &--tertiary-user {
        z-index: 3;
        background-color: #fff !important;
        top: 85%;
        opacity: 0;
        transition: all 0.3s ease;
        visibility: hidden;
        display: inherit !important;
        width: 200px;
        position: absolute;
        right: 0;
        text-align: left;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);

        ul {
          padding: 24px 24px 24px;

          li {
            &.navigation__item {
              width: 100%;
              margin: 0 0 16px;

              &:last-child {
                margin-bottom: 0;
              }

              button {
                &.navigation__link {
                  padding: 0;
                  font-size: 18px;
                }

                &:hover {
                  color: red;
                }
              }
            }
          }
        }
      }

      &__menu-arrow {
        cursor: pointer;
        transition: 0.2s all linear 0.2s;
        overflow: hidden;

        &:before,
        &:after {
          position: absolute;
          display: none;
          top: 90%;
          left: 50%;
          content: "";
          transform: translate(-50%, 0);
          width: 0;
          height: 0;
          border-style: solid;
        }

        &:before {
          z-index: 2;
          border-width: 0 9px 10px 9px;
          border-color: transparent transparent #ffffff transparent;
        }

        &:after {
          z-index: 1;
          border-width: 0 10px 10px 10px;
          border-color: transparent transparent #ccc transparent;
        }
      }
    }
  }

  a {
    font-weight: 500;
  }

  &__internal {
    float: right;
  }

  &__spring.tab-list {
    display: table;
    margin: 0 auto;

    .navigation__link {
      &--active {
        color: black;
        font-family: "VodafoneRegularBold";
      }

      &:hover {
        color: black;
      }
    }
  }

  .topLink-icon {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }

  .margin-right-1 {
    margin-right: 8px;
  }

  .user-icon {
    line-height: 28px;
    border-radius: 50%;
    font-size: 16px;
    font-family: "VodafoneRegular";
    color: #fff;
    text-align: center;
    background: #007c92;
  }
}

@media only screen and (max-width: 1055px) {
  .navigation {
    &.navigation--primary-ws10 {
      .navigation {
        &__link {
          padding: 4px 20px;

          &--main {
            padding: 16px 20px 12px;
            display: flex;
            align-items: center;
          }
        }

        &__site {
          margin-top: 0;
        }

        &__menu-arrow {
          &:before,
          &:after {
            top: 110%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1055px) and (min-width: 1024px) {
  .navigation {
    &.navigation--primary-ws10 {
      &__item {
        &.hide--md {
          display: block !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .navigation {
    &.navigation_ws10 {
      .navigation {
        &__site {
          margin-top: 0;
          padding: 4px 20px;
        }
      }
    }

    .user-icon {
      line-height: 24px;
      font-size: 14px;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .navigation {
    &.navigation--primary-ws10 {
      .brand {
        width: 32px;
        height: 32px;

        &__logo {
          width: 32px;
          height: 32px;
        }
      }

      .mega-menu--mobile__container {
        .navigation__toggle {
          & + span {
            font-size: 18px;
            margin-left: 8px;
          }
        }
      }
    }

    &__item {
      &.hide--md {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 639px) {
  .navigation {
    &.navigation--primary-ws10 {
      .navigation {
        &__link {
          &--menu svg {
            width: 20px;
            height: 20px;
          }

          &--main {
            padding: 10px 0;
            margin: 0;
          }
        }

        &--tertiary-user {
          width: 140px;

          ul {
            padding: 20px 24px;

            li {
              &.navigation__item {
                button {
                  &.navigation__link {
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .user-icon {
      line-height: 20px;
      font-size: 12px;
    }
  }
}
