.bundle-summary {
    position: fixed;
    top: calc(100% - 60px);
    left: 0;
    right: 0;
    height: 100vh;
    background: $color-gallery;
    z-index: 50;
    transition: top 0.4s;

    &--complete {
        top: calc(100% - 106px);
    }

    &--complete &__body {
        height: calc(100% - 106px);
    }

    @media (min-width: $breakpoint-tablet) {
        top: calc(100% - 70px);
    }

    @media (min-width: $breakpoint-desktop) {
        top: calc(100% - 80px);
    }

    &--open {
        top: 0;

        .bundle-summary-header__expand-button > svg {
            transform: rotate(0.5turn);
        }
    }

    &__body {
        height: calc(100% - 60px);
        overflow-y: auto;

        @media (min-width: $breakpoint-tablet) {
            height: calc(100% - 70px);
        }

        @media (min-width: $breakpoint-desktop) {
            height: calc(100% - 80px);
        }
    }
}

.bundle-summary-header {
    &__main {
        font-size: 20px;
        white-space: nowrap;
        line-height: 0;

        @media (min-width: $breakpoint-desktop) {
            font-size: 28px;
        }
    }

    &__total {
        display: inline-block;
        height: 1em;
        padding: 0 15px;
        font-size: 1em;
        line-height: 1;

        &:first-child {
            padding-left: 0;
        }

        &:not(:first-child) {
            border-left: 1px solid $color-white;
        }

        @media (min-width: $breakpoint-tablet) {
            float: left;
            padding: 0 20px;
        }
    }

    &__status-icon {
        position: relative;
        display: inline-block;
        width: 1em;
        height: 1em;
        margin-top: 0;
        border-radius: 50%;
        background-color: $color-green;
        vertical-align: middle;
        font-size: 1.25em;
        color: $color-white;

        @media (min-width: $breakpoint-tablet) {
            margin-top: -0.125em;
        }
    }

    &__status-icon-inner > svg {
        @include position;

        width: 0.5em;
        height: 0.5em;
    }

    &__price-main {
        font-size: 1em;
        font-weight: bold;
    }

    &__price-suffix {
        font-size: 0.7em;
    }

    &__expand-button {
        padding: 10px;
        margin-left: 30px;

        > svg {
            transition: transform 0.4s;
        }
    }

    &__checkout {
        &--hide {
            display: none;
        }
    }
}

@media only screen and (max-width: $breakpoint-tablet) {
    .bundle-summary-header {
        &__expand-button {
            position: absolute;
            right: 5px;
            top: 5px;
        }

        &__total {
            padding: 0 5px;
        }
    }
}

.bundle-summary-options {
    &__heading {
        float: left;
        width: 100%;
        margin-bottom: 10px;
    }

    &__fieldset {
        padding-top: 40px;
        padding-bottom: 45px;
    }

    .card__content:not(:last-child) &__fieldset {
        border-bottom: 1px solid $color-silver;
    }

    &__fieldset-option {
        margin-top: 25px;
    }

    &__offer-highlight {
        color: $color-red;
    }
}

@media only screen and (max-width: $breakpoint-tablet) {
    .bundle-summary-options {
        .card__content:last-child &__fieldset {
            padding-bottom: 95px;
        }
    }
}
