.progress {
  margin: 40px auto;
  height: 6px;
  position: relative;
  background: $color-silver;
  border-radius: 5px;
  overflow: hidden;

  &__bar {
    background: $color-tundora;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    transition: width 0.4s;
  }

  &--primary &__bar {
    background: $color-red;
  }

  &--secondary {
    background: $color-ironside-gray;
  }

  &--secondary &__bar {
    background: $color-wild-sand;
  }

  &--seance {
    background: $color-ironside-gray;
  }

  &--seance &__bar {
    background: $color-seance;
  }

  &--cerulean {
    background: $color-ironside-gray;
  }

  &--cerulean &__bar {
    background: $color-cerulean;
  }
}
