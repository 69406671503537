.social {
    margin: 35px 0 34px;

    &__item {
        display: inline-block;
        padding: 0 15px;

        &:first-child {
            padding-left: 0;
        }

        &--small {
            padding: 0 3px;
        }
    }

    &__community-text {
        margin-right: 10px;
    }

    &__community {
        margin: 0;
        text-align: right;
    }
}

@media only screen and (max-width: $breakpoint-tablet) {
    .social {
        text-align: center;
        margin-top: 25px;

        &__community {
            margin-top: 17px;
            float: none;
            display: inline-block;
            text-align: center;
        }

        &__list {
            margin-bottom: 10px;
        }
    }
}
