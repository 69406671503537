.login-nudge {
    position: relative;

    &--active &__link {
        background-color: $color-white;
        color: $color-red;
        position: relative;
        z-index: 1;

        &::after {
            background-color: currentColor;
            content: "";
            position: absolute;
            bottom: 0;
            height: 3px;
            left: 0;
            right: 0;
        }
    }

    &__prompt {
        background-color: hex-to-rgba($color-white, 0.95);
        color: $color-mine-shaft;
        padding: 15px;
        position: absolute;
        right: 0;
        text-align: center;
        width: 250px;
        box-shadow: 0 2px 4px 0 hex-to-rgba($color-black, 0.2);
    }

    &__login {
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: $breakpoint-desktop) {
    .login-nudge {
        &--active &__link {
            &::after {
                height: 2px;
            }
        }

        &__prompt {
            right: -43px;
        }
    }
}

@media only screen and (max-width: $breakpoint-tablet) {
    .login-nudge {
        &__prompt {
            right: -40px;
        }
    }
}
