.location-nudge {
    &__location {
        display: block;
        font-weight: $font-weight-bold;
    }

    &__button {
        padding: 15px 20px;
    }

    &__map {
        height: 100%;
    }
}

@media only screen and (max-width: $breakpoint-tablet) {
    .location-nudge {
        &__map {
            height: 250px;
        }
    }
}
