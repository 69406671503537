.complete-purchase {
  padding: 98px 0 168px;

  &__section {
    display: flex;
    flex-direction: column;
    max-width: 610px;
    margin: auto;

    &--center {
      align-items: center;
      text-align: center;
    }
  }

  .heading {
    line-height: 1.3;
  }

  p {
    margin: 24px 0;
    font-size: 16px;
  }

  &--icon {
    background-color: $color-blue-lagoon;
    color: $color-white;
    width: 95px;
    height: 95px;
    border-radius: 50%;
    margin: 40px 0 32px;
    position: relative;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      stroke: white;
      width: 30px;
      height: 30px;
      stroke-width: 5px;
    }
  }

  &--table {
    margin-top: 8px;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    li {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      line-height: 1.4;
      padding: 12px 0;
      border-bottom: 1px solid $color-gallery;
    }
  }

  &--actions {
    flex-direction: row;
    // justify-content: space-between;
    justify-content: center;
    margin-top: 80px;

    button {
      min-width: 210px;

      &.button--tertiary {
        border: 1px solid $color-dusty-gray;
      }
    }
  }
}

@media only screen and (max-width: 639px) {
  .complete-purchase {
    padding: 49px 0 64px;

    > .spring {
      padding: 0 20px;
    }

    &__section {
      &--center {
        align-items: flex-start;
        text-align: left;
      }
    }

    &--icon {
      margin-top: 56px;
    }

    .heading {
      font-size: 28px;
    }

    p {
      margin: 10px 0;
    }

    &--table {
      margin-top: 20px;
    }

    &--actions {
      flex-direction: column;
      margin-top: 64px;
    }
  }
}
