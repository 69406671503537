.list {
  margin: 0;
  padding-left: 20px;

  &--reset {
    list-style: none;
    padding-left: 0;
  }

  &--no-style {
    list-style: none;
  }

  &--overflow {
    overflow: hidden;
  }

  &__item {
    &--lined {
      border-bottom: 1px solid $color-alto;
      margin: 0;
      padding: 20px 20px 20px 15px;
      text-align: left;
    }

    &--last {
      border-bottom: none;
    }

    &-separator {
      border-left: 1px solid currentColor;
      display: inline-block;
      padding: 0 15px;
      text-decoration: underline;

      &--first {
        border-left: none;
        padding-left: 0;
      }
    }
  }

  &__horizontal {
    display: inline;
    padding-right: 10px;
  }

  &__term {
    font-family: $font-family-vodafone-regular-bold;
    margin-top: 20px;
  }

  &__definition {
    margin: 0;
  }
}
