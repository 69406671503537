.search-form {
    &__wrapper {
        background-color: $color-mine-shaft-light;
        padding: 40px 0;

        &--transparent {
            background: none;
        }
    }

    &__query {
        margin: 0 auto;
        max-width: 580px;
    }
}

@media only screen and (max-width: $breakpoint-tablet) {
    .search-form {
        &__wrapper {
            padding: 20px 10px;
        }
    }
}
