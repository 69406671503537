.pass-card {
    display: block;
    height: 43px;
    width: 100%;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    position: relative;
    text-align: left;
    overflow: hidden;
    background-color: transparent;
    box-shadow: 0 -3px 3px 0 hex-to-rgba($color-black, 0.3),
        inset 0 8px 8px hex-to-rgba($color-black, 0.3);
    margin-bottom: -12px;
    z-index: 1;
    transition: height 0.67s cubic-bezier(0.77, 0, 0.175, 1); // easeInOutQuart

    &::before {
        // The notch
        content: "";
        width: 10px;
        height: 10px;
        box-shadow: 70px 70px 0 100px $color-red, 0 0 0 999px $color-white;
        position: absolute;
        top: -6px;
        right: 60px;
        border-radius: 50%;
    }

    &__label-container {
        display: inline-block;
        font-size: 20px;
        position: absolute;
        left: 0;
        top: 0;
        padding: 10px 0 0 10px;
        color: $color-red;
        height: 100%;
        width: 125px;
        z-index: 1;

        &--no-pass {
            width: 70px;
        }
    }

    &__label-full {
        position: absolute;
        opacity: 1;
        transition: opacity 0.27s cubic-bezier(0.165, 0.84, 0.44, 1) 0.27s; //easeOutQuart
    }

    &__label-name {
        position: absolute;
        opacity: 0;
        transition: opacity 0.27s cubic-bezier(0.165, 0.84, 0.44, 1); //easeOutQuart
    }

    &__symbol {
        position: absolute;
        top: 6px;
        left: 100%;
        transition: left 0.27s cubic-bezier(0.165, 0.84, 0.44, 1) 0.27s; //easeOutQuart

        &--green {
            color: $color-pistachio;
        }

        &--blue {
            color: $color-cerulean;
        }

        &--orange {
            color: $color-tangerine;
        }

        &--purple {
            color: $color-finn;
        }
    }

    &__icons {
        position: absolute;
        top: 100%;
        left: 0;
        bottom: 0;
        right: 0;
        margin-left: 30%;
        padding: 13px 0;
        z-index: 0;
        transition: top 0.33s cubic-bezier(0.47, 0, 0.745, 0.715); //easeInSine
    }

    &__icon {
        width: 36px;
        height: 36px;
    }

    &__link {
        border: 1px solid $color-white;
        border-radius: 5px;
        color: $color-white;
        display: inline-block;
        height: 36px;
        line-height: 36px;
        margin-right: 12px;
        text-align: center;
        visibility: hidden;
        transition: visibility 0.33s;
        width: 36px;
    }

    &__dots {
        background: url("../../images/group/images-non-responsive/vodafone-pass-dots.svg") no-repeat;
        height: 163px;
        position: absolute;
        top: 8px;
        right: 64px;
        width: 2px;
    }

    &--tall {
        height: 50px;
    }

    &--expanded {
        height: 75px;
        transition: height 1s cubic-bezier(0.77, 0, 0.175, 1); //easeInOutQuart
    }

    &--expanded &__label-full {
        opacity: 0;
        transition: opacity 0.27s cubic-bezier(0.165, 0.84, 0.44, 1); //easeOutQuart
    }

    &--expanded &__label-name {
        opacity: 1;
        transition: opacity 0.27s cubic-bezier(0.165, 0.84, 0.44, 1) 0.27s; //easeOutQuart
    }

    &--expanded &__symbol {
        left: -50px;
        transition: left 0.27s cubic-bezier(0.895, 0.03, 0.685, 0.22); //easeInQuart
        transition-delay: 0.13s;
    }

    &--expanded &__icons {
        top: 0;
        transition: top 0.53s cubic-bezier(0.19, 1, 0.22, 1); //easeOutExpo
        transition-delay: 0.33s;
    }

    &--expanded &__link {
        visibility: visible;
        transition: visibility 0.53s;
        transition-delay: 0.33s;
    }

    &--last::after {
        // Bottom shadow for the last pass
        content: "";
        box-shadow: inset 0 -8px 8px -4px hex-to-rgba($color-black, 0.3);
        display: block;
        width: 100%;
        height: 10px;
        position: absolute;
        bottom: 10px;
        z-index: 1;
    }

    &--disabled::before {
        box-shadow: 70px 60px 0 100px $color-silver-chalice,
            0 0 0 999px $color-white;
    }

    &--disabled &__symbol {
        color: $color-silver-chalice;
    }
}

@media only screen and (max-width: $breakpoint-tablet) {
    .pass-card {
        height: 38px;

        &__label-container {
            font-size: inherit;
            padding-top: 7px;
        }

        &__icons {
            padding: 9px 0;
            margin-left: 25%;
        }

        &__icon,
        &__link {
            width: 28px;
            height: 28px;
            line-height: 28px;
        }

        &__link {
            margin-right: 18px;
        }

        &--expanded {
            height: 55px;
        }
    }
}
