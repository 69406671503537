body {
  min-height: auto;
}

.dashboard {
  padding: 50px 40px;
  min-height: calc(100vh - 98px);
  background-color: #F7F5F6;
  overflow-x: hidden;

  .slick-prev:before,
  .slick-next:before {
    color: #CCCCCC;
    font-size: 25px;
  }

  &__header {
    h1 {
      margin: 0 0 10px;
      font-size: 24px;
    }

    h3 {
      font-family: "VodafoneRegularBold";

      span {
        font-family: "VodafoneRegular";
        margin-left: 25px;
        font-size: 16px;
      }
    }

    .button {
      &--transparent {
        border: 0;
        border-radius: 0;
        padding: 0;
        margin-left: 25px;

        &:first-child {
          margin-left: 0;
        }
      }

      &--small {
        font-size: 16px;
        padding: 8px 30px;
      }
    }

    .icon {
      &--logout {
        transform: rotate(-90deg);
      }
    }
  }

  .grid {
    &__item {
      &--gutter {
        padding: 10px;
      }
    }
  }

  &__content {
    margin: 0 -15px;

    &--flex {
      &.grid {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
      }

      .card {
        height: 100%;
        flex: 1;
      }

      .grid__item {
        &:first-child {
          padding-right: 8px;
        }

        &:nth-child(2) {
          padding-left: 8px;
        }
      }
    }

    &--double {
      &.grid {
        margin: -15px;
      }

      &.dashboard__content--flex {
        display: flex;
        flex-direction: column;

        .grid__item {
          flex: 1;

          &:first-child {
            padding-right: 15px;
            padding-bottom: 8px;
          }

          &:nth-child(2) {
            padding-left: 15px;
            padding-top: 8px;
          }
        }
      }

      .grid__item {
        padding: 15px;
      }
    }
  }

  &__item {
    &--1\/2 {
      display: flex;
      flex: 1 1 50%;
    }
  }

  &__dark--mode {
    background-color: #222222;
    color: #ffffff;

    .heading {
      color: #ffffff;
    }

    .progress {
      background: rgba(235, 235, 235, 0.15);

      &--primary {
        .progress__bar {
          background: #ffffff;
        }
      }
    }

    .card {
      background-color: #333333;
      color: #ffffff;

      &__info {
        p {
          color: #CCCCCC;
        }
      }

      &__color {
        &--grey {
          background-color: #ffffff;
          color: #333333;

          .heading {
            color: #333333;
          }
        }

        &--red {
          background: #990000;
          background: linear-gradient(90deg, #990000 0%, #E60000 55%);
        }
      }

      .toggle-switch__input:checked~.toggle-switch__indicator-wrapper .toggle-switch__indicator {
        background-color: #00B0CA;
        border-color: #00B0CA;
      }
    }
  }

  .card {
    &__link {
      margin-top: 10px;
    }

    &__content {
      text-align: left;
    }
  }

  &__menu {
    margin-top: 20px;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 16px 0;
    font-size: 20px;
    border-bottom: 1px solid #ebebeb;
    cursor: pointer;
    
    &--text {
      padding-right: 16px;
    }

    &--title {
      padding-left: 20px;
    }

    &--arrow {
      color: #E60000;
    }
  }
}

@media only screen and (max-width: 968px) {
  .dashboard {
    padding: 50px 0px;

    &__content {
      margin: 0 -10px;;
    }

    .spring {
      padding: 0 16px;
    }

    .card {
      &__info {
        h5 {
          span {
            font-size: 48px;
          }

          .unit {
            font-size: 32px;
          }
        }
      }

      &--amount {
        font-size: 48px;

        span {
          font-size: 32px;
        }
      }
    }
  }

  .progress {
    margin: 15px 0 5px;
  }
}

@media only screen and (max-width: 639px) {
  .dashboard {
    overflow-x: hidden;
    padding: 20px 0;

    &__header {
      &--person {
        h3 {
          span {
            display: block;
            margin-left: 0;
            margin-top: 10px;
          }
        }
      }

      &--actions {
        vertical-align: top;
        margin-top: 20px;

        button {
          width: auto;
          display: inline-block;
          margin-bottom: 0;
        }
      }
    }

    .card {
      &__info {
        h5 {
          font-size: 16px;

          span {
            font-size: 42px;
          }

          .unit {
            font-size: 28px;
          }
        }
      }

      &--amount {
        font-size: 42px;

        span {
          font-size: 28px;
        }
      }
    }

    &__menu {
      margin-top: 16px;
    }

    &__link {
      padding: 12px 0;

      .icon {
        width: 24px;
        height: 24px;
      }

      &--title {
        font-size: 18px;
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .dashboard {
    .spring {
      min-width: auto;
    }
  }
}
