.brand {
  text-align: center;
  margin: 12px 10px 12px 0;

  &__logo {
    position: relative;
    width: 48px;
    height: 48px;
    vertical-align: top;

    &--documentation {
      margin-top: -10px;
      width: 85px;
      margin-left: 30px;
    }

    &--standaloneheaderbrandlogo {
      position: relative;
      width: 48px;
      height: 48px;
    }
  }
}

@media only screen and (max-width: $breakpoint-desktop) {
  .brand {
    margin: 10px 10px 10px 0;

    &__logo {
      margin-top: 0px;
    }
  }
}

@media only screen and (max-width: $breakpoint-tablet) {
  .brand {
    margin: 8px 10px 8px 0;
  }
}
