.brand {
    margin: 12px 10px 12px 0;

    &__logo {
        position: relative;
        width: 48px;
        height: 48px;
        vertical-align: top;

        &--documentation {
            margin-top: -10px;
            width: 85px;
            margin-left: 30px;
        }
    }
}

@media only screen and (max-width: $breakpoint-desktop) {
    .brand {
        margin: 10px 10px 10px 0;
        width: 40px;
        height: 40px;

        &--docs {
            margin: 10px 0 0;
        }

        &__logo {
            height: 100%;
            width: 100%;
        }
    }
}

@media only screen and (max-width: $breakpoint-tablet) {
    .brand {
        margin: 8px 10px 8px 0;
        left: 10px;
        width: 32px;
        height: 32px;

        &--docs {
            margin: 10px 0 0;
        }
    }
}
