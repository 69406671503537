.DayPicker--icon {
  bottom: 38px;
  position: relative;
  right: 10px;
  pointer-events: none;

  &-disabled {
    opacity: 0.5;
  }
}

.rdt {
  position: relative;
  letter-spacing: normal;
  font-family: VodafoneRegular;

  &Days table thead {
    background-color: #ebebeb;
  }

  &Picker {
    display: none;
    position: absolute;
    z-index: 99999 !important;
    background: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid #f9f9f9;

    th.rdtDisabled {
      background: none;
      color: #999999;
      cursor: not-allowed;

      &:hover {
        background: none;
        color: #999999;
        cursor: not-allowed;
      }
    }

    thead tr:first-child th {
      cursor: pointer;

      &:hover {
        background: #eeeeee;
      }
    }

    tfoot {
      border-top: 1px solid #f9f9f9;
    }

    button {
      border: none;
      background: none;
      cursor: pointer;

      &:hover {
        background-color: #eee;
      }
    }

    thead button {
      width: 100%;
      height: 100%;
    }
  }

  &Open .rdtPicker {
    display: block;
    width: 276px;
    border-color: #d6d6d6;
    margin-top: 10px;
    padding: 0;
  }

  &Static .rdtPicker {
    box-shadow: none;
    position: static;
  }

  &Picker {
    .rdtTimeToggle {
      text-align: center;
    }

    table {
      width: 100%;
      margin: 0;
    }

    td,
    th {
      vertical-align: middle;
      text-align: center;
      height: 40px;
    }

    td {
      cursor: pointer;
      border-radius: 50%;
      text-align: center;
      vertical-align: middle;

      &.rdtDay:hover,
      &.rdtHour:hover,
      &.rdtMinute:hover,
      &.rdtSecond:hover {
        background: #eeeeee;
        cursor: pointer;
      }
    }

    .rdtTimeToggle:hover {
      background: #eeeeee;
      cursor: pointer;
    }

    td {
      &.rdtOld,
      &.rdtNew {
        color: #999999;
      }

      &.rdtToday {
        position: relative;

        &:before {
          content: "";
          display: inline-block;
          border-left: 7px solid transparent;
          border-bottom: 7px solid #e60000;
          border-top-color: rgba(0, 0, 0, 0.2);
          position: absolute;
          bottom: 4px;
          right: 4px;
        }
      }

      &.rdtActive {
        background-color: #e60000;
        color: #fff;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);

        &:hover {
          background-color: #e60000;
          color: #fff;
          text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
        }

        &.rdtToday:before {
          display: none;
        }
      }

      &.rdtDisabled {
        background: none;
        color: #999999;
        cursor: not-allowed;

        &:hover {
          background: none;
          color: #999999;
          cursor: not-allowed;
        }
      }

      span {
        &.rdtOld {
          color: #999999;
        }

        &.rdtDisabled {
          background: none;
          color: #999999;
          cursor: not-allowed;

          &:hover {
            background: none;
            color: #999999;
            cursor: not-allowed;
          }
        }
      }
    }

    th {
      border-bottom: 1px solid #f9f9f9;
    }

    .dow {
      width: 14.2857%;
      border-bottom: none;
      cursor: default;
    }

    th {
      &.rdtSwitch {
        width: 100%;
        height: 60px;
        font-weight: 700;
        position: relative;
        text-align: center;
        vertical-align: middle;
      }

      &.rdtNext,
      &.rdtPrev {
        font-size: 21px;
        vertical-align: top;
      }
    }
  }

  &Prev span,
  &Next span {
    border-top: 1.5px solid black;
    border-right: 1.5px solid black;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    position: relative;
    top: 10px;
    margin: 10px 10px;
    display: block;
    user-select: none;
    font-size: 0;
  }

  &Prev span {
    transform: rotate(-135deg);
  }

  &Counters {
    display: inline-block;

    > div {
      float: left;
    }
  }

  &Counter {
    height: 100px;
    width: 40px;

    .rdtBtn {
      height: 40%;
      line-height: 40px;
      cursor: pointer;
      display: block;
      user-select: none;

      &:hover {
        background: #eee;
      }
    }

    .rdtCount {
      height: 20%;
      font-size: 1.2em;
    }
  }

  &CounterSeparator {
    line-height: 100px;
  }

  &Milli {
    vertical-align: middle;
    padding-left: 8px;
    width: 48px;

    input {
      width: 100%;
      font-size: 1.2em;
      margin-top: 37px;
    }
  }

  &Time td {
    cursor: default;
  }
}

td {
  &.rdtMonth,
  &.rdtYear {
    height: 50px;
    width: 25%;
    cursor: pointer;
    border-radius: 0;
  }

  &.rdtMonth:hover,
  &.rdtYear:hover {
    background: #eee;
  }
}
