.jumper {
    background-color: $color-dove-gray;
    color: $color-white;
    left: -9999em;
    padding: 5px;
    position: absolute;
    z-index: 10000;

    &:focus {
        left: 0;
    }
}
