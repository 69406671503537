.button {
  appearance: none;
  border: 1px solid transparent;
  cursor: pointer;
  display: inline-block;
  font-size: 20px;
  padding: 11px 37px;
  text-align: center;

  &:hover {
    cursor: pointer;
  }

  &--reset {
    border: none;
    padding: 0;
    background: none;
  }

  &--primary {
    color: $color-white;
    background-color: $color-red;

    &--dark {
      background-color: $color-guardsman-red;
    }

    &:hover {
      background-color: $color-red-berry;
    }
  }

  &--secondary {
    color: $color-white;
    background-color: $color-dove-gray;

    &:hover {
      background-color: $color-mine-shaft;
    }

    &--dark {
      background-color: #afafaf;
      color: $color-mine-shaft;

      &:hover {
        background-color: $color-silver;
      }
    }
  }

  &--tertiary {
    background-color: $color-white;
    border-color: $color-dusty-gray;
    color: $color-dove-gray;

    &:hover {
      background-color: $color-wild-sand;
      border-color: $color-mine-shaft;
      color: $color-mine-shaft;
    }

    &--dark {
      background-color: transparent;
      border-color: $color-silver;
      color: $color-white;

      &:hover {
        background-color: $color-wild-sand;
      }

      &:focus {
        color: $color-mine-shaft;
        background-color: $color-wild-sand;
      }
    }
  }

  &--confirm {
    color: $color-white;
    background-color: $color-limeade;

    &:hover {
      background-color: #295300;
    }
  }

  &--transparent {
    background-color: transparent;
    border-color: currentColor;
    color: currentColor;
  }

  &--full-width {
    display: block;
    width: 100%;
    text-align: center;
  }

  &--square {
    width: 50px;
    height: 50px;
    padding: 0;
    margin: 0;
  }

  &--trailing {
    margin-bottom: 10px;
  }

  &--disabled,
  &--disabled:hover,
  &--disabled:focus {
    background-color: $color-silver;
    border-color: $color-silver;
    color: $color-white;
    cursor: default;
  }

  &--disabled--dark,
  &--disabled--dark:hover,
  &--disabled--dark:focus,
  &--disabled.button--primary--dark,
  &--disabled.button--primary--dark:hover,
  &--disabled.button--primary--dark:focus,
  &--disabled.button--secondary--dark,
  &--disabled.button--secondary--dark:hover,
  &--disabled.button--secondary--dark:focus {
    background-color: $color-dove-gray;
    border-color: $color-dove-gray;
    color: $color-mine-shaft;
    cursor: default;
  }

  &__icon {
    margin-right: 10px;
  }
}

@media only screen and (max-width: $breakpoint-tablet) {
  .button {
    display: block;
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
    padding: 10px;
    width: 100%;

    &--inline {
      display: inline-block;
      width: auto;
    }

    &--square {
      width: 45px;
      height: 45px;
      float: right;
      margin-right: 5px;
    }
  }
}
