.horizontal-text-rule {
    position: relative;
    text-align: center;

    &:before {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        position: absolute;
        background-color: $color-silver;
        transform: translateY(-50%);
        top: 50%;
    }

    &__text {
        position: relative;
        display: inline-block;
        width: auto;
        padding: 0 10px;
        background-color: $color-white;
    }
}

@media only screen and (max-width: $breakpoint-desktop) {
    .horizontal-text-rule {
        margin: 10px 0;
    }
}

@media only screen and (max-width: $breakpoint-tablet) {
    .horizontal-text-rule {
        margin: 0;
    }
}
