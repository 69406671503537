.toggle-switch {
  text-align: center;

  &--border {
    border-bottom: 1px solid $color-silver;
    padding-bottom: 20px;
  }

  &__trigger {
    cursor: pointer;
    display: inline-block;
    text-align: center;
  }

  &--full &__trigger {
    display: table;
    width: 100%;
    text-align: left;
  }

  &__input {
    position: absolute;
    z-index: -1;
  }

  &__title {
    display: inline-block;
    vertical-align: middle;

    &--hide {
      @include visually-hidden;
    }
  }

  &__feedback--on {
    display: none;
  }

  &__input:checked + &__title &__feedback--on {
    display: inline-block;
  }

  &__input:checked + &__title &__feedback--off {
    display: none;
  }

  &__input:focus + &__title {
    color: $color-blue-lagoon;
    outline: 2px solid $color-cerulean;
  }

  &__indicator {
    border: 1px solid $color-silver;
    border-radius: 56px;
    display: inline-block;
    height: 28px;
    margin-left: 10px;
    transition: all 0.6s;
    width: 50px;
  }

  &--full &__title,
  &--full &__indicator-wrapper {
    display: table-cell;
  }

  &--full &__title {
    width: 100%;
  }

  &__input:checked ~ &__indicator-wrapper &__indicator {
    background-color: $color-blue-lagoon;
    border-color: $color-blue-lagoon;
  }

  &__indication {
    background-color: $color-white;
    border-radius: 50%;
    box-shadow: 0 1px 2px $color-black;
    display: block;
    height: 26px;
    margin-left: 0;
    position: relative;
    transition: margin-left 0.3s;
    width: 26px;
  }

  &__input:checked ~ &__indicator-wrapper &__indication {
    margin-left: calc(50% - 2px);
  }
}

.mouse-active .toggle-switch__input:focus + .toggle-switch__title {
  outline: none;
  color: inherit;
}
