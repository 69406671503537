.spinner {
  text-align: center;

  svg {
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    stroke-width: 1px;
    height: 40px;
    width: 40px;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  &__container {
    width: 100%;
    padding: 32px 0;
    margin-bottom: 32px;
  }
}
