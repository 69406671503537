.banner {
  &__background {
    &--image {
      width: 100%;
      z-index: 0;
      background-size: cover;
      background-position: center;
    }
  }

  &__wrapper {
    position: relative;
    height: 550px;
    overflow: hidden;
  }

  &__text {
    &--left {
      .banner__content {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        padding: 0 32px 0 16px;

        .heading {
          color: #fff;
        }

        .heading--3 {
          font-family: "VodafoneRegularBold";
          margin: 0 0 16px 0;
        }

        .heading--4 {
          font-family: "VodafoneRegular";
          font-size: 18px;
          line-height: 1.33;
          margin: 0 0 24px 0;
        }
      }
    }
  }
}

//Tablet
@media only screen and (max-width: 968px) {
  .banner {
    &__wrapper {
      height: 310px;
    }

    &__text {
      &--left {
        .banner__content {
          width: 100%;

          .heading--3 {
            font-size: 24px;
            margin-bottom: 6px;
            line-height: 1.3;
          }

          .heading--4 {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}

// Mobile
@media only screen and (max-width: 639px) {
  .banner {
    &__text {
      &--left {
        .banner__content {
          .heading--3 {
            font-size: 20px;
          }

          .heading--4 {
            font-size: 16px;
            margin-bottom: 24px;
          }
        }
      }
    }
  }
}
