.hero {
    background-color: $color-mine-shaft;
    color: $color-white;
    position: relative;
    text-align: center;
    overflow: hidden;

    &__message {
        padding: 45px 10px 40px;
    }

    &--tall &__message {
        padding-top: 55px;
        padding-bottom: 80px;
    }

    &--white {
        background-color: $color-white;
        color: $color-black;
    }

    &__heading {
        line-height: 67px;
        margin-bottom: 20px;
    }

    &__sub-message {
        max-width: 560px;
        margin: 0 auto 20px;
    }

    &__background {
        z-index: 0;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: hex-to-rgba($color-mine-shaft, 0.5);
            background: linear-gradient(
                to bottom,
                transparent 20%,
                hex-to-rgba($color-black, 0.9) 100%
            );
            transition: background 1s ease-in 1s;
        }

        &--no-tint::after {
            content: none;
        }
    }

    &--animate &__background {
        opacity: 1;
        transform: scale(1.1);
        transition: opacity 1s ease-in, transform 10s ease-in;
    }

    &__band {
        position: relative;
        text-align: center;
    }

    &__button {
        width: auto;
        display: inline-block;
        padding: 11px 37px;
    }

    &--animate &__band {
        opacity: 1;
        top: 0;
        transition: top 0.5s ease-out 2s, opacity 0.5s ease-out 2s;
    }

    &__icons {
        display: block;
        margin: 0 auto;
        margin-bottom: 12px;
    }
}

@media only screen and (max-width: $breakpoint-desktop) {
    .hero {
        &__message {
            padding: 55px 10px;

            &--basic {
                padding: 0;
            }
        }
    }
}

@media only screen and (max-width: $breakpoint-tablet) {
    .hero {
        &__message {
            padding: 25px 10px 15px;

            &--basic {
                padding: 0;
            }
        }

        &__heading {
            line-height: 36px;
            margin-bottom: 15px;
            padding: 0;
        }

        &--tall &__message {
            padding: 45px 10px 77px;
        }

        &--tall &__heading {
            margin-bottom: 30px;
        }

        &__caption {
            margin-top: 40px;
        }

        &__description {
            &--break-sm {
                word-spacing: 99999px;
            }
        }
    }
}

.preload {
    .hero {
        &--animate &__band {
            opacity: 1;
            top: 0;
        }

        &--animate &__background {
            opacity: 1;
        }

        &__band {
            opacity: 0;
            top: 50px;
        }

        &__background {
            opacity: 0;
            transform: scale(1);

            &::after {
                background: transparent;
            }
        }
    }
}
